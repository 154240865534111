
<template>
  <div class="ev-nav">
    <div v-if="isHideControl()" class="ev-button-page-control" @click="toggleHideCotrol()" title="Show filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>
    <div v-else class="ev-button-page-control" @click="toggleHideCotrol()" title="Hide filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>

    <div class="ev-options__value-contaier">
      <span><input v-model="classes.get('eventTypes').view" type="checkbox" name="eventType" @change="changeViewEventTypes()"/></span>
      <label class="checkbox-label" for="eventType">Event</label>
    </div>

    <div class="ev-options__value-contaier">
      <span><input v-model="classes.get('countries').view" type="checkbox" name="countries" @change="changeViewCountries()"/></span>
      <label class="checkbox-label" for="countries">Countries</label>
    </div>

    <div class="ev-options__value-contaier">
      <span><input v-model="classes.get('classHibiInfoItems').view" type="checkbox" name="classHibiInfoItems" @change="changeViewClassHibiInfoItems()"/></span>
      <label class="checkbox-label" for="classHibiInfoItems">Info</label>
    </div>
  
  </div>

  <div class="ev-page-main__container" v-if="renderComponent">
    <div class="ev-calendar-control" v-if="!isHideControl()">
      <div  class="ev-calendar-control__class-date">

        <button class="hb-button" @click="preFilterEventItems">
          Apply 
        </button>
        <div class="ev-calendar-control__button-wrapper">
          <button class="hb-button-short" @click="prevWeek">
            &lt;&lt;
          </button>
        </div>
        <div class="ev-calendar-control__button-wrapper">
          <button class="hb-button-short" @click="prevDay">
            &lt;
          </button>
        </div>
        <div class="ev-calendar-control__date-wrapper">
          <input type="date" placeholder="Set Date" v-model="eventDate" required>
        </div>

      </div>
      
      <div class="ev-calendar-control__class" v-if="classView('eventTypes')">
        <Class :editMode="false" :classOptions="classesOptions.get('eventTypes').event_classOptions"/>
      </div>

      <div class="ev-calendar-control__class" v-if="classView('countries')">
        <Class :editMode="false" :classOptions="classesOptions.get('countries').event_classOptions"/>
      </div>

      <div class="ev-calendar-control__class-long" v-if="classView('classHibiInfoItems')">
        <Class :editMode="false" :classOptions="classesOptions.get('classHibiInfoItems').event_classOptions"/>
      </div>

    </div>

    <div class="ev-page-main__view" :style="{ height: viewHeight }">
      <hibiCalendar v-if="list !== null" :events="list" />
    </div>

  </div>

</template>

<script>  
import Class from '@/components/classes/class.vue'
import hibiCalendar from '@/components/hibicalendar/hibicalendar-cell.vue'
import emitter from '@/emitter.js'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'EventView',
  components: { 
    Class, hibiCalendar },
  data () {
    const classesMap = new Map();

    return {
      renderComponent: true,
      classes: classesMap,

      eventDate: '',

      themeOptions: {},
      filter: {},
      list: [],

      navHeight: 72,
      topHeight: 0,

    }
  },

  computed: {
    ...mapState({
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2,
    }),
    viewHeight () {
      let height
      if (this.themeOptions.view == true) {
        height = this.navHeight + 34
      } else {
        height = this.navHeight
      }

      this.setTopHeight(height)

      return 'calc(100vh - ' + height + 'px)'
    }

  },

  async created () {
    try {

      this.classes.set('time', this.classesOptions.get('time').event_classOptions)
      this.classes.set('eventTypes', this.classesOptions.get('eventTypes').event_classOptions)
      this.classes.set('countries', this.classesOptions.get('countries').event_classOptions)
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').event_classOptions)

      this.themeOptions = this.themesOptions.get('event').event_themeOptions

      this.filter = this.themeOptions.filter
      this.eventDate = this.filter.time.vl.substring(0, 10)

      await this.setFilter()

      await this.$store.dispatch('getThemeItems', this.themeOptions)

      this.list = this.themesOptions.get('event').data

    } catch (error) {
      console.log('Calendar created error:', error)
    } finally {

      for (let cls of this.classes.values()) {
        emitter.all.delete(cls.filter.event)
        emitter.on(cls.filter.event, () => {
          this.handleFilter (cls)
        })
      }

      emitter.all.delete('eventComponentFilter')
      emitter.on('eventComponentFilter', () => {
        this.preFilterEventItems()
        //console.log('eventComponentFiltered')
      })      

      emitter.all.delete('eventComponentRerender')
      emitter.on('eventComponentRerender', () => {
        this.forceRerender()
        //console.log('eventComponentRerender')
      })

      this.forceRerender()

      // console.log('Events finally', this.$store.getters.eventsItems, this.$store.getters.eventsValue, this.value )
    }
  },

  methods: {
    isHideControl() {

      if (!this.themeOptions.view) {
        return true
      } else {
        return false 
      }
    },
    toggleHideCotrol () {
      this.themeOptions.view = !this.themeOptions.view
    },
    setTopHeight (height) {
      this.topHeight = height
    },

    changeViewEventTypes() {
      //if (!this.classes.get('eventTypes').view) {
        this.handleFilter(this.classes.get('eventTypes'))
      //}
    },
    changeViewCountries() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('countries'))
      //}
    },
    changeViewClassHibiInfoItems() {
      //if (!this.classes.get('classHibiInfoItems').view) {
        this.handleFilter(this.classes.get('classHibiInfoItems'))
      //}
    },
    classView (className) {
      return this.classes.get(className).view
    },

    async setFilter () {

      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }
      
    },

    handleFilter (cls) {
      
      switch (cls.className) {

        case 'eventTypes':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.event = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.event = this.filter.event + cls.filter.vl[i] + ','
                }
                this.filter.event = this.filter.event.substring(0, this.filter.event.length-1)
              } else {
                this.filter.event = cls.filter.vl.toString()
              }
              this.filter.event = '{' + this.filter.event + '}'
            } else {
              this.filter.event = '{}'  
            }
          
          } else {
            this.filter.event = '{0}'
          }

          //console.log('this.filter.event: ', this.filter.event)
        
        break;

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
              } else {
                this.filter.locality = cls.filter.vl.toString()
              }
              this.filter.locality = '{' + this.filter.locality + '}'
            } else {
              this.filter.locality = '{}'  
            }
          
          } else {
            this.filter.locality = '{0}'
          }

          //console.log('this.filter.locality: ', this.filter.locality)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
            } else {
              this.filter.clsfrs = '{}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;
      }

      //console.log('handleFilter this.filter, cls: ', this.filter, cls)
    },

    preFilterEventItems () {
      if (this.filter.event !== '{}' && this.filter.locality !== '{}' && this.filter.clsfrs !== '{}') {
        this.filterEventItems ()
      } else {
        window.prompt('When a classifier is selected, at least one item must be selected. \n Please select a classifier item or deselect a classifier. ');
      }
    },

    async filterEventItems () {
      try {
        
        this.filter.time.vl = moment(this.eventDate).format()
      
        await this.$store.dispatch('getThemeItems', this.themeOptions)

        this.list = this.themesOptions.get('event').data

        this.forceRerender()
          
      } catch (error) {
        console.log('filterEventsItems error:', error)
      }
    },

    prevWeek () {
      const d = new Date(this.eventDate)
      d.setDate(d.getDate() - 7)
      this.eventDate = d.toISOString().substring(0, 10)
      this.preFilterEventItems()
    },
    prevDay () {
      const d = new Date(this.eventDate)
      d.setDate(d.getDate() - 1)
      this.eventDate = d.toISOString().substring(0, 10)
      this.preFilterEventItems()
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/events.scss'
</style>
