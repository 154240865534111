import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import LinkPreviewBlockComponent from './LinkPreviewBlockComponent.vue'

export default Node.create({
	name: 'LinkPreview',

	group: 'block',

	content: 'block+',

	draggable: true,

	addAttributes() {
		return {
			view: {
				default: 'false',
				parseHTML: (element) => {
					//console.log ('element.getAttribute(view): ', element.getAttribute('view'))
					//element.hidden = true
					return element.getAttribute('view')
				},
				renderHTML: (attributes) => {
					return {
						view: attributes.view
					};
				}
			},
			link: {
				default: '',
				parseHTML: (element) => {
					return element.getAttribute('link')
				},
				renderHTML: (attributes) => {
					return {
						link: attributes.link
					};
				}
			},
			uploaded: {
				default: 'false',
				parseHTML: (element) => {
					return element.getAttribute('uploaded')
				},
				renderHTML: (attributes) => {
					return {
						uploaded: attributes.uploaded
					};
				}
			},
			title: {
				default: 'Title',
				parseHTML: (element) => {
					return element.getAttribute('title')
				},
				renderHTML: (attributes) => {
					return {
						title: attributes.title
					};
				}
			},
			domain: {
				default: 'Domain',
				parseHTML: (element) => {
					return element.getAttribute('domain')
				},
				renderHTML: (attributes) => {
					return {
						domain: attributes.domain
					};
				}
			},
			description: {
				default: 'Description',
				parseHTML: (element) => {
					return element.getAttribute('description')
				},
				renderHTML: (attributes) => {
					return {
						description: attributes.description
					};
				}
			},
			image: {
				default: '',
				parseHTML: (element) => {
					return element.getAttribute('image')
				},
				renderHTML: (attributes) => {
					return {
						image: attributes.image
					};
				}
			},
			content: {
				default: '',
				parseHTML: (element) => {
					return element.getAttribute('content')
				},
				renderHTML: (attributes) => {
					return {
						content: attributes.content
					};
				}
			},

		}
	},

	parseHTML() {
		return [
			{
				tag: 'div[data-type="link-preview"]',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'link-preview' }), 0]
	},

	addNodeView() {
		return VueNodeViewRenderer(LinkPreviewBlockComponent)
	},
})