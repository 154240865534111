import axios_http from '@/http/index.js'

const state = {
  classesResult: null,
  classesItems: [],
  newsThemesItems: [],
  newsSourcesItems: [],
  classesItems100:[],
  classesItems101:[],
  classesItems102:[],
  classesItems103:[]
}

const mutations = {
  UPDATE_CLASSES_ITEMS_RESULT (state, payload) {
    state.classesResult = payload
  },
  UPDATE_CLASSES_ITEMS (state, payload) {
    state.classesItems = payload
  },
  UPDATE_NEWSTHEMES_ITEMS (state, payload) {
    state.newsThemesItems = payload
  },
  UPDATE_NEWSSOURCES_ITEMS (state, payload) {
    state.newsSourcesItems = payload
  },

  UPDATE_CLASSES_ITEMS_100 (state, payload) {
    state.classesItems100 = payload
  },
  UPDATE_CLASSES_ITEMS_101 (state, payload) {
    state.classesItems101 = payload
  },
  UPDATE_CLASSES_ITEMS_102 (state, payload) {
    state.classesItems102 = payload
  },
  UPDATE_CLASSES_ITEMS_103 (state, payload) {
    state.classesItems103 = payload
  }
}

const actions = {
  async getClassesItems ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('GET classes master data call unsuccessful :(', error)
      })
  },
  async getNewsThemesItems ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_NEWSTHEMES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('GET NewsThemes master data call unsuccessful :(', error)
      })
  },
  async getNewsSourcesItems ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_NEWSSOURCES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('GET NewsSources master data call unsuccessful :(', error)
      })
  },

  async getClassesItems100 ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_100', response.data)
      })
      .catch((error) => {
        console.log('GET classes_100 master data call unsuccessful :(', error)
      })
  },
  async getClassesItems101 ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_101', response.data)
      })
      .catch((error) => {
        console.log('GET classes_101 master data call unsuccessful :(', error)
      })
  },
  async getClassesItems102 ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_102', response.data)
      })
      .catch((error) => {
        console.log('GET classes_102 master data call unsuccessful :(', error)
      })
  },
  async getClassesItems103 ({ commit }, classId) {
    await axios_http
      .post('/getclasses', {Id: classId})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_103', response.data)
      })
      .catch((error) => {
        console.log('GET classes_103 master data call unsuccessful :(', error)
      })
  },


  async addClassesItem ({commit}, payload) {
    //console.log(payload)
    await axios_http
      .post('/addclass', {uploadedItem: payload.uploadedItem})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_RESULT', response.data)
        //console.log(response.data)
      })
      .catch((error) => {
        console.log('Add classes item master data call unsuccessful :(', error)
      })
  },

  async changeClassesItem ({commit}, payload) {
    await axios_http
      .post('/changeclass', {uploadedItem: payload.uploadedItem})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_RESULT', response.data)
        //console.log(response.data)
      })
      .catch((error) => {
        console.log('Update classes master data call unsuccessful :(', error)
      })
  },


  async updateClassesItems ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('Update classes master data call unsuccessful :(', error)
      })
  },
  async updateNewsThemesItems ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_NEWSTHEMES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('Update NewsThemes master data call unsuccessful :(', error)
      })
  },
  async updateNewsSourcesItems ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_NEWSSOURCES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('Update NewsSources master data call unsuccessful :(', error)
      })
  },

  async updateClassesItems100 ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_100', response.data)
      })
      .catch((error) => {
        console.log('Update classes_100 master data call unsuccessful :(', error)
      })
  },
  async updateClassesItems101 ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_101', response.data)
      })
      .catch((error) => {
        console.log('Update classes_101 master data call unsuccessful :(', error)
      })
  },
  async updateClassesItems102 ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_102', response.data)
      })
      .catch((error) => {
        console.log('Update classes_102 master data call unsuccessful :(', error)
      })
  },
  async updateClassesItems103 ({ commit }, payload) {
    await axios_http
      .post('/classes', {updatedItems: payload.updatedItems, Id: payload.Id})
      .then((response) => {
        commit('UPDATE_CLASSES_ITEMS_103', response.data)
      })
      .catch((error) => {
        console.log('Update classes master data call unsuccessful :(', error)
      })
  }

}

const getters = {
  classesResult: state => state.classesResult,
  classesItems: state => state.classesItems,
  newsThemesItems: state => state.newsThemesItems,
  newsSourcesItems: state => state.newsSourcesItems,

  classesItems100: state => state.classesItems100,
  classesItems101: state => state.classesItems101,
  classesItems102: state => state.classesItems102,
  classesItems103: state => state.classesItems103

}

const classesModule = {
  state,
  mutations,
  actions,
  getters
}

export default classesModule
