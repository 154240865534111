<!--
/**
 * @fileoverview TaskListItem component
 * @license MIT
 * @author Rafal Pospiech <neuronet.io@gmail.com>
 * @package GanttElastic
 */
-->

<template>
  <div class="gantt-elastic__task-list-item" :style="{ ...style['task-list-item'] }">
    <ItemColumn v-for="column in columns" :key="column._id" :column="column" :task="task">
      <TaskListExpander
        v-if="column.expander"
        :tasks="[task]"
        :options="state.options.taskList.expander"
        type="taskList"
      ></TaskListExpander>
    </ItemColumn>
  </div>
</template>

<script>
import { inject } from 'vue';
import TaskListExpander from '../expander.vue';
import ItemColumn from './itemColumn.vue';

export default {
  name: 'TaskListItem',
  components: {
    TaskListExpander,
    ItemColumn
  },
  props: ['task'],
  data() {
    return {};
  },
  setup () {
    const state = inject('state')
    const style = inject('style')
    
    return { state, style  }
  },

  computed: {
    columns() {
      //console.log('this.state.options.taskList.columns: ', this.state.options.taskList.columns)
      return this.state.options.taskList.columns;
    }
  }
};
</script>
