<template>
  <div class="files-container">
    <FileItem v-for="(item, index) in files"
      :key=index
      :file="item"
    />
  </div>
</template>

<script>
import FileItem from './files-item.vue'

export default {
  name: 'FilesCell',
  props: ['files'],
  components: {
    FileItem
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/files-cell.scss'
</style>
