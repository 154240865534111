<template>
  <div class="main-window">
    <div class="mn-page-main__side">
    </div>

    <main>
      <div class="main-panel">
        <div class="content-all">          

          <div class="content-header">
            <h1 class="title">Log In</h1>
          </div>

          <div class="content-body login">
            <div class="field">
              <input v-model="fields.email" type="text" placeholder="Enter email ..." />
            </div>
            <div class="field">
              <span style="color: red"> {{ fieldErrors.email }}</span>
              <br>
            </div>
            <div class="group-field">
              <div class="field" style="flex-grow: 10000">
                <input :type="passwordType" v-model="fields.pswrd" placeholder="Enter password ..."/>
              </div>
              <div class="field" style="margin-left: 12px">
                <button v-if="isHide" @click="viewPassword" style="cursor: pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                </button>
                <button v-if="!isHide" @click="viewPassword" style="cursor: pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                  <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                </button>
              </div>
            </div>
            <div class="field">
              <span style="color: red"> {{ fieldErrors.pswrd }}</span>
              <br>
            </div>
            <div class="group-field">
              <div class="field" style="margin-top: 1rem; margin-bottom: 1rem">
                <button class="hb-button" @click="submitForm()" style="width: 100%;">
                  Log In
                </button>
              </div>
            </div>
<!--            
            <div class="group-field" style="margin-top: 1em">
              <div class="field">
                <p style="margin-right: 0.5em">Don't have an account?</p>
              </div>
              <div class="field">
                <button class="hb-button" @click="toSignUp()" style="width: 100%;">
                  Sign Up
                </button>
              </div>
            </div>
-->
          </div>

        </div>

      </div>
    </main>
    
    <div class="mn-page-main__side">
    </div>
    
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginBox',
  data () {
    return {
      isHide: true,
      passwordType: "password",
      fields: {
        email: '',
        pswrd: ''
      },
      fieldErrors: {
        email: undefined,
        pswrd: undefined
      }
    }
  },
  computed: {
    ...mapGetters([
      'loading'
    ])
  },
  methods: {
    viewPassword () {
      this.passwordType = this.passwordType === "password" ? "text" : "password"; 
      this.isHide = !this.isHide
      return this.isHide
    },
    toSignUp () {
      this.$router.push({ path: '/signup' })
    },
    submitForm () {
      this.fields.email = this.fields.email.replace(/\s +/g, '').toLowerCase()

      this.fieldErrors = this.validateForm(this.fields)
      if (Object.keys(this.fieldErrors).length) return

      this.$store.dispatch('auth_login', this.fields)
        .then(() => {
          //console.log('login')
          this.fields.email = ''
          this.fields.pswrd = ''
          this.$router.push({ path: '/' })
        })
        .catch((error) => {
          this.fields.email = ''
          this.fields.pswrd = ''
          this.fieldErrors.pswrd = 'Log In failed; Invalid email or password.'
          console.log('Log In failed; Invalid email or password.', error)
        })
    },
    validateForm (fields) {
      const errors = {}
      if (!fields.email) errors.email = 'Enter email ...';
      if (fields.email && !this.isEmail(fields.email)) {
        errors.email = 'Invalid Email';
      }
      if (!fields.pswrd) errors.pswrd = 'Enter password ...'
      if (fields.pswrd && !this.isPassword(fields.pswrd)) {
        errors.pswrd = 'Check password: 8 to 64 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character';
      }

      return errors
    },
    isEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    isPassword(password) {
      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/;
      return re.test(password);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/main.scss';
</style>
