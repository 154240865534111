// import axios from 'axios'
import axios_http from '@/http/index.js'
import moment from 'moment'

const d = new Date()
d.setDate(d.getDate() - 1)
const newsDate = new Date()
newsDate.setDate(newsDate.getDate() - 8)

const eventClassesMap = new Map();
eventClassesMap.set('time', {
  className: 'time',
  classType: 0,
  vl: moment(d.toISOString().substring(0, 10)).format(),
  vle: moment('2122-12-31').format(),
  view: true,
  filter: {
    options: {

    },
    event: 'eventFilteredTime',
    vl: null
  }
});
eventClassesMap.set('eventTypes', {
  className: 'eventTypes',
  classType: 1,
  classId: 1,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'eventFilteredEventTypes',
    vl: [1]
  }
});
eventClassesMap.set('countries', {
  className: 'countries',
  classType: 2,
  classId: 1,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'eventFilteredCountries',
    vl: [1]
  }
});
eventClassesMap.set('classHibiInfoItems', {
  className: 'classHibiInfoItems',
  classType: 3,
  classId: 2,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'eventFilteredClassHibiInfoItems',
    vl: [2]
  }
});

const knowledgeClassesMap = new Map();
knowledgeClassesMap.set('countries', {
  className: 'countries',
  classType: 2,
  classId: 1,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'knowledgeFilteredCountries',
    vl: [1]
  }
});
knowledgeClassesMap.set('classHibiInfoItems', {
  className: 'classHibiInfoItems',
  classType: 3,
  classId: 2,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'knowledgeFilteredClassHibiInfoItems',
    vl: [2]
  }
});

const newsClassesMap = new Map();
newsClassesMap.set('time', {
  className: 'time',
  classType: 0,
  vl: moment(newsDate.toISOString().substring(0, 10)).format(),
  vle: moment('2122-12-31').format(),
  view: true,
  filter: {
    options: {

    },
    event: 'newsFilteredTime',
    vl: null
  }
});
newsClassesMap.set('countries', {
  className: 'countries',
  classType: 2,
  classId: 1,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'newsFilteredCountries',
    vl: [1]
  }
});
newsClassesMap.set('classHibiInfoItems', {
  className: 'classHibiInfoItems',
  classType: 3,
  classId: 2,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'newsFilteredClassHibiInfoItems',
    vl: [2]
  },
});
newsClassesMap.set('classNewsThemes', {
  className: 'classNewsThemes',
  classType: 3,
  classId: 20000000,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'newsFilteredClassNewsThemes',
    vl: [20000000]
  },
});
newsClassesMap.set('classNewsSources', {
  className: 'classNewsSources',
  classType: 3,
  classId: 21000000,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'newsFilteredClassNewsSources',
    vl: [21000000]
  },
});


const eventUploadClassesMap = new Map();
eventUploadClassesMap.set('time', {
  className: 'time',
  classType: 0,
  vl: moment(d.toISOString().substring(0, 10)).format(),
  vle: moment('2122-12-31').format(),
  view: true,
  filter: {
    options: {

    },
    event: 'eventUploadFilteredTime',
    vl: null
  }
});
eventUploadClassesMap.set('eventTypes', {
  className: 'eventTypes',
  classType: 1,
  classId: 1,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'eventUploadFilteredEventTypes',
    vl: [1]
  }
});
eventUploadClassesMap.set('countries', {
  className: 'countries',
  classType: 2,
  classId: 1,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'eventUploadFilteredCountries',
    vl: [1]
  }
});
eventUploadClassesMap.set('classHibiInfoItems', {
  className: 'classHibiInfoItems',
  classType: 3,
  classId: 2,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'eventUploadFilteredClassHibiInfoItems',
    vl: [2]
  }
});

const knowledgeUploadClassesMap = new Map();
knowledgeUploadClassesMap.set('countries', {
  className: 'countries',
  classType: 2,
  classId: 1,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'knowledgeUploadFilteredCountries',
    vl: [1]
  }
});
knowledgeUploadClassesMap.set('classHibiInfoItems', {
  className: 'classHibiInfoItems',
  classType: 3,
  classId: 2,
  view: false,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'knowledgeUploadFilteredClassHibiInfoItems',
    vl: [2]
  }
});

const newsUploadClassesMap = new Map();
newsUploadClassesMap.set('time', {
  className: 'time',
  classType: 0,
  vl: moment(newsDate.toISOString().substring(0, 10)).format(),
  vle: moment('2122-12-31').format(),
  view: true,
  filter: {
    options: {

    },
    event: 'newsUploadFilteredTime',
    vl: null
  }
});
newsUploadClassesMap.set('countries', {
  className: 'countries',
  classType: 2,
  classId: 1,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: false 

    },
    event: 'newsUploadFilteredCountries',
    vl: [1]
  }
});
newsUploadClassesMap.set('classHibiInfoItems', {
  className: 'classHibiInfoItems',
  classType: 3,
  classId: 2,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: true 

    },
    event: 'newsUploadFilteredClassHibiInfoItems',
    vl: [2]
  },
});
newsUploadClassesMap.set('classNewsThemes', {
  className: 'classNewsThemes',
  classType: 3,
  classId: 20000000,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: false,

    },
    event: 'newsUploadFilteredClassNewsThemes',
    vl: [20000000]
  },
});
newsUploadClassesMap.set('classNewsSources', {
  className: 'classNewsSources',
  classType: 3,
  classId: 21000000,
  view: true,
  filter: {
    options: {
      multiple: true,
      flat: true,
      valueconsistsof: 'ALL',
      disablebranchnodes: false,
      fuzzyMatching: true,
      flattenSearchResults: false,
      searchNested: false,
      showCount: true,
      closeonselect: false,
      fullpath: false, 

    },
    event: 'newsUploadFilteredClassNewsSources',
    vl: [21000000]
  },
});


let state = new Map();

state.set('time', { 
  event_classOptions: eventClassesMap.get('time'),
  eventUpload_classOptions: eventUploadClassesMap.get('time'),
  news_classOptions: newsClassesMap.get('time'),
  newsUpload_classOptions: newsUploadClassesMap.get('time'),
  data: [],
  result: 0
});
state.set('classHibiInfoItems', { 
  knowledge_classOptions: knowledgeClassesMap.get('classHibiInfoItems'), 
  knowledgeUpload_classOptions: knowledgeUploadClassesMap.get('classHibiInfoItems'), 
  event_classOptions: eventClassesMap.get('classHibiInfoItems'), 
  eventUpload_classOptions: eventUploadClassesMap.get('classHibiInfoItems'), 
  news_classOptions: newsClassesMap.get('classHibiInfoItems'),
  newsUpload_classOptions: newsUploadClassesMap.get('classHibiInfoItems'),
  data: [],
  result: 0
});
state.set('classNewsThemes', { 
  news_classOptions: newsClassesMap.get('classNewsThemes'),
  newsUpload_classOptions: newsUploadClassesMap.get('classNewsThemes'),
  data: [],
  result: 0
});
state.set('classNewsSources', { 
  news_classOptions: newsClassesMap.get('classNewsSources'),
  newsUpload_classOptions: newsUploadClassesMap.get('classNewsSources'),
  data: [],
  result: 0
});
state.set('eventTypes', { 
  event_classOptions: eventClassesMap.get('eventTypes'), 
  eventUpload_classOptions: eventUploadClassesMap.get('eventTypes'), 
  data: [],
  result: 0
});
state.set('countries', { 
  knowledge_classOptions: knowledgeClassesMap.get('countries'), 
  knowledgeUpload_classOptions: knowledgeUploadClassesMap.get('countries'), 
  event_classOptions: eventClassesMap.get('countries'), 
  eventUpload_classOptions: eventUploadClassesMap.get('countries'), 
  news_classOptions: newsClassesMap.get('countries'), 
  newsUpload_classOptions: newsUploadClassesMap.get('countries'), 
  data: [],
  result: 0
})

const mutations = {
  UPDATE_CLASSES_ITEMS (state, payload) {
    //console.log('state: ', state);
    //console.log('payload: ', payload);

    state.get( payload.className).data = payload.data

  },

  UPDATE_CLASSES_ITEMS_RESULT (state, payload) {
    //console.log('state: ', state);
    //console.log('payload: ', payload);

    //state.set( payload.classOptions.className, { classData: payload.classData, data: payload.data })
    state.get( payload.className).result = payload.data

  },

}

const actions = {
  async getClassesItems_1 ({ commit }, payload) {
    //console.log('payload: ', payload)
    switch (payload.classType) {
      case 1: //eventType
        await axios_http
          .get('/eventtypes')
          .then((response) => {
            let result = {}
            result.data = response.data
            result.className = payload.className
            //console.log('result: ', result)

            commit('UPDATE_CLASSES_ITEMS', result)
          })
          .catch((error) => {
            console.log('GET eventtypes master data call unsuccessful :(', error)
          })
        
        break;
      
      case 2: //locality
        await axios_http
          .get('/admdivs')
          .then((response) => {
            let result = {}
            result.data = response.data
            result.className = payload.className
            //console.log('result: ', result)

            commit('UPDATE_CLASSES_ITEMS', result)
          })
          .catch((error) => {
            console.log('GET eventtypes master data call unsuccessful :(', error)
          })
  
        break;
      
      case 3: //other
        await axios_http
          .post('/getclasses', {Id: payload.classId})
          .then((response) => {
            let result = {}
            result.data = response.data
            result.className = payload.className

            commit('UPDATE_CLASSES_ITEMS', result)
          })
          .catch((error) => {
            console.log('GET classes master data call unsuccessful :(', error)
          })
    
        break;
    } 

  },

  async changeClassesItem_1 ({commit}, payload) {
    switch (payload.classOptions.classType) {
      case 1: //eventType:
        await axios_http
          .post('/changeeventtypes', {uploadedItem: payload.uploadedItem})
          .then((response) => {
            //console.log('response.data: ', response.data)
            if (response.data != 0) {
              console.log('Update classes master data call unsuccessful :(', payload.classOptions.className)
            }
            let result = {}
            result.data = response.data
            result.className = payload.classOptions.className
            commit('UPDATE_CLASSES_ITEMS_RESULT', result)
          })
          .catch((error) => {
            console.log('Update classes master data call unsuccessful :(', error)
          })
        
      break;

      case 2: //locality
        await axios_http
          .post('/changeadmdivs', {uploadedItem: payload.uploadedItem})
          .then((response) => {
            //console.log('response.data: ', response.data)
            if (response.data != 0) {
              console.log('Update classes master data call unsuccessful :(', payload.classOptions.className)
            }
            let result = {}
            result.data = response.data
            result.className = payload.classOptions.className
            commit('UPDATE_CLASSES_ITEMS_RESULT', result)
          })
          .catch((error) => {
            console.log('Update classes master data call unsuccessful :(', error)
          })

      break;

      case 3: //other
        await axios_http
          .post('/changeclass', {uploadedItem: payload.uploadedItem})
          .then((response) => {
            //console.log('response.data: ', response.data)
            if (response.data != 0) {
              console.log('Update classes master data call unsuccessful :(', payload.classOptions.className)
            }
            let result = {}
            result.data = response.data
            result.className = payload.classOptions.className
            commit('UPDATE_CLASSES_ITEMS_RESULT', result)
          })
          .catch((error) => {
            console.log('Update classes master data call unsuccessful :(', error)
          })

      break;
    }
    
  },

  async addClassesItem_1 ({commit}, payload) {
    //console.log(payload)
    switch (payload.classOptions.classType) {
      case 1: //eventType:
        await axios_http
          .post('/addeventtypes', {uploadedItem: payload.uploadedItem})
          .then((response) => {

            let result = {}
            result.data = response.data
            result.className = payload.classOptions.className

            commit('UPDATE_CLASSES_ITEMS_RESULT', result)
            //console.log(response.data)
          })
          .catch((error) => {
            console.log('Add classes item master data call unsuccessful :(', error)
          })
        
      break;

      case 2: //locality
        await axios_http
          .post('/addadmdivs', {uploadedItem: payload.uploadedItem})
          .then((response) => {

            let result = {}
            result.data = response.data
            result.className = payload.classOptions.className

            commit('UPDATE_CLASSES_ITEMS_RESULT', result)
            //console.log(response.data)
          })
          .catch((error) => {
            console.log('Add classes item master data call unsuccessful :(', error)
          })
    
      break;

      case 3: //other
        await axios_http
          .post('/addclass', {uploadedItem: payload.uploadedItem})
          .then((response) => {

            let result = {}
            result.data = response.data
            result.className = payload.classOptions.className

            commit('UPDATE_CLASSES_ITEMS_RESULT', result)
            //console.log(response.data)
          })
          .catch((error) => {
            console.log('Add classes item master data call unsuccessful :(', error)
          })

      break;
    }

  },

}

const getters = {
  classes: state => state
}

const classesModule = {
  state,
  mutations,
  actions,
  getters
}

export default classesModule
