import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import InstagramBlockComponent from './InstagramBlockComponent.vue'

export default Node.create({
  //!!!The name must be unique in the Editor
  name: 'InstagramBlockComponent',

  group: 'block',
  atom: true,

  addOptions: {
		HTMLAttributes: {
			class: "instagram-media"
		}
	},

	addAttributes() {
		return {
			hibiHtml: {
				default: ``,
				parseHTML: element => element.innerHTML,
				//(element) => {
				//	const img = element.querySelector('img');

				//	return {
				//	  src: img.dataset.src || img.getAttribute('src')}
				//	}
			},
      beginTag: {
        default: '<hibi-instagram-media>'
      },
      endTag: {
        default: '</hibi-instagram-media>'
      }
		}
	},
	parseHTML () {
		return [
			{tag: 'hibi-instagram-media'
				//tag: 'div[class="twitter-tweet twitter-tweet-rendered"]',
			},
			{priority: 100},
			//{consuming: true},
			//{context: 'hibi-instagram-media/blockquote'},
			//{ignore: true},
		]
	},
	renderHTML({HTMLAttributes}) {
		const el = document.createElement('hibi-instagram-media');
		//el.classList.add('instagram-media');
		//const el = document.createElement('div');
		//el.classList.add('twitter-tweet');
		//el.classList.add('twitter-tweet-rendered');
		
		el.innerHTML = HTMLAttributes['hibiHtml'];
		//console.log("HTMLAttributes['hibiHtml']", HTMLAttributes['hibiHtml'], el, window.instagram)

		return el
	},

  addNodeView() {
    return VueNodeViewRenderer(InstagramBlockComponent)
  },
})