<template>
  <div class="nw-preview-item">

    <div class="nw-news-top" :style="getChannelBackgroundColor">
      <div class="nw-news-classes">
        <span class="nw-news-class">
          {{ getClsfrs }}
        </span>
        <span class="nw-news-class">
          , {{ getCountries }}
        </span>
      </div>
    </div>

    <div class="nw-day-banner" :style="getChannelBackgroundColor">
      <strong>{{ dayMonth }} {{ dayDay }}</strong>&nbsp;&nbsp; {{ dayWeekday }} &nbsp;
      {{ dayHour }} : {{ dayMinute }} ({{ dayUTC }})<strong> &nbsp; {{ dayYear }}</strong>
    </div>

    <AppLink :to="getLinkItem">

      <div class="nw-news-preview">

        <div class="nw-news-annot">
          <h2 v-if="titleItem().svl != ''" class="nw-news-title" v-html="titleItem().svl" />
          <h2 v-else class="nw-news-title">Link to article</h2>

          <div class="nw-news-text" v-html="annotationItem().svl" />

        </div>

        <img v-if="multimediaItem().svl != ''" class="nw-news-image" :src="multimediaItem().svl" />

      </div>

    </AppLink>

  </div>
</template>

<script>
import AppLink from '@/components/router/applink.vue'

export default {
  name: 'NewsListItemView',
  props: ['item'],
  components: {
    AppLink
  },
  data () {

    const d = new Date(this.item.time.vl)
    const timeRTF = new Intl.DateTimeFormat('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      fractionalSecondDigits: 3,
      hour12: false,
      timeZone: this.item.time.tz
    })

    return {

      dayWeekday: timeRTF.formatToParts(d).find((el) => (el.type === 'weekday')).value, //.toUpperCase(),
      dayDay: timeRTF.formatToParts(d).find((el) => (el.type === 'day')).value,
      dayMonth: timeRTF.formatToParts(d).find((el) => (el.type === 'month')).value,  //.substring(0, 3),
      dayYear: timeRTF.formatToParts(d).find((el) => (el.type === 'year')).value,
      dayHour: timeRTF.formatToParts(d).find((el) => (el.type === 'hour')).value,
      dayMinute: timeRTF.formatToParts(d).find((el) => (el.type === 'minute')).value,
      dayUTC: this.item.time.vl.substring(19,25)
    }
  },

  created () {
    //console.log('item: ', this.item);
  },

  computed: {

    getLinkItem () {
      return '/news/' + this.item.id;
    },
    getDate () {
      const option = {weekday: 'long'}
      const dt = new Date(this.item.time.vl)
      const wd = new Intl.DateTimeFormat('ru-RU', option).format(dt)
      return this.item.time.vl.substring(0,10) + ' ' + this.item.time.vl.substring(11,16) + ', ' + wd
    },
    getCountries () {
      const classes = this.item.countries.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getClsfrs () {
      const classes = this.item.clsfrs.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getChannelBackgroundColor () {

      const clsfrs = this.item.clsfrs.ids.substring(1,this.item.clsfrs.ids.length-1).split(',')

      let find = false
      let value = 20000000
      for (let i = 1; i < clsfrs.length; i++) {
        if (clsfrs[i] > 20000000 && clsfrs[i] < 21000000 && !find) {
          value = clsfrs[i]
          find = true;
        }
      }

      let bc = ``
      switch (value % 20000000) {
        case 0:
          bc = `background-color: ${'rgb(145,187,212)'}`
        break;

        case 1:
          bc = `background-color: ${'rgb(163,179,192)'}`
        break;
      
        case 2:
          bc =  `background-color: ${'rgb(153,149,166)'}`
        break;

        case 3:
          bc =  `background-color: ${'rgb(69,73,85)'}`
          //bc =  `background-color: ${'rgb(147,125,103)'}`
        break;

        case 4:
          bc =  `background-color: ${'rgb(96,124,148)'}`
        break;

        case 5:
          bc =  `background-color: ${'rgb(74,91,84)'}`
        break;

        case 6:
          bc =  `background-color: ${'rgb(194,181,164)'}`
        break;

        case 7:
          bc =  `background-color: ${'rgb(71,74,32)'}`
        break;

        case 8:
          bc =  `background-color: ${'rgb(69,73,85)'}`
        break;

        case 9:
          bc =  `background-color: ${'rgb(18,44,68)'}`
        break;

        case 10:
          bc =  `background-color: ${'rgb(97,64,61)'}`
        break;
      }

      return bc; 
    }

  },

  methods: {
    viewNews () {
      this.$router.push(this.getLinkItem);
    },

    titleItem () {
      return this.item.msrs.find( (itm) => itm.vid == 20);
    },
    annotationItem () {
      return this.item.msrs.find( (itm) => itm.vid == 24);
    },
    multimediaItem () {
      return this.item.msrs.find( (itm) => itm.vid == 23);
    },

    htmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == 19)
    },
  },

}
</script>

<style lang="scss" scoped>
  @import '@/assets/news-list-view-item.scss';

</style>
