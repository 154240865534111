// import axios from 'axios'
import axios_http from '@/http/index.js'

const state = {
  dataItems: [],
  dataValue: null,
  updataItem: [],
  updataValue: null
}

const mutations = {
  UPDATE_DATA_ITEMS (state, payload) {
    if (payload != '') {
      
      state.dataItems = payload
    } else {
      state.dataItems = []
    }
  },
  UPDATE_DATA_VALUE (state, payload) {
    state.dataValue = payload
  },
  UPDATE_UPDATA_ITEM (state, payload) {
    if (payload != '') {
      state.updataItem = payload
    } else {
      state.updataItem = []
    }
  },
  UPDATE_UPDATA_VALUE (state, payload) {
    state.updataValue = payload
  }
}

const actions = {

  async filterDataItems ({ commit }, payload) {
    await axios_http
      .post('/data/filter', payload.filter)
      .then((response) => {
        //console.log('GET /data/filter call successful: ', response.data)
        commit('UPDATE_DATA_ITEMS', response.data)
        commit('UPDATE_DATA_VALUE', payload.value)
      })
  },
  async getDataItem ({ commit }, payload) {
    // console.log('api/events')
    await axios_http
      //.get('/myhibi/' + selectedValue.toString())
      .post('/data/filter', payload.filter)
      .then((response) => {
        //console.log('GET call api/medcond/ successful: ', payload, response, )
        commit('UPDATE_UPDATA_ITEM', response.data)
        if (response.data !== '') {
          commit('UPDATE_UPDATA_VALUE', payload.value)
        } else {
          commit('UPDATE_UPDATA_VALUE', null)
        }
      })
      .catch((error) => {
        console.log('GET node data call unsuccessful :(', error)
      })
  },
  async uploadDataItem ({ commit }, dataItem) {
    //console.log('dataItem:', dataItem)
    await axios_http
      .post('/data/upload', dataItem)
      .then((response) => {
        // console.log('UPDATE_MEDCOND_ITEM: ', response.data)
        commit('UPDATE_UPDATA_ITEM', response.data)
        //if (response.data !== '') {
        //  commit('UPDATE_UPDATA_VALUE', dataItem.value)
        //} else {
        //  commit('UPDATE_UPDATA_VALUE', null)
        //}
        //console.log('response: ', response.data, MedcondItem)
      })
      .catch((error) => {
        console.log('POST upload myhibi data call unsuccessful :(', error)
      })
  }
}

const getters = {
  dataItems: state => state.dataItems,
  dataValue: state => state.dataValue,
  updataItem: state => state.updataItem,
  updataValue: state => state.updataValue
}

const dataModule = {
  state,
  mutations,
  actions,
  getters
}

export default dataModule
