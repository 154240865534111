// import axios from 'axios'
import axios_http from '@/http/index.js'

const state = {
  valuesItems: []
}

const mutations = {
  UPDATE_VALUES_ITEMS (state, payload) {
    state.valuesItems = payload
  }
}

const actions = {
  async getValuesItems ({ commit }) {
    await axios_http
      .get('/values')
      .then((response) => {
        commit('UPDATE_VALUES_ITEMS', response.data)
        // console.log(response.data)
      })
      .catch((error) => {
        console.log('GET values master data call unsuccessful :(', error)
      })
  },
  async updateValuesItems ({ commit }, updatedValuesItems) {
    await axios_http
      .post('/values', updatedValuesItems)
      .then((response) => {
        commit('UPDATE_VALUES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('Update values master data call unsuccessful :(', error)
      })
  }
}

const getters = {
  valuesItems: state => state.valuesItems
}

const valuesModule = {
  state,
  mutations,
  actions,
  getters
}

export default valuesModule
