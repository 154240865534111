<template>
  <div class="files-container">
    <LinksItem v-for="(item, index) in links"
      :key=index
      :link="item"
    />
  </div>
</template>

<script>
import LinksItem from './links-item.vue'

export default {
  name: 'LinksCell',
  props: ['links'],
  components: {
    LinksItem
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/files-cell.scss'
</style>
