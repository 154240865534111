  // Event object
const EventItem = {
  "id": 0,
  "account": 1,
  "event": {
    "id": 1,
    "nm": "HI-BI Event Types"
  },
  "events": {
    "ids": "{0,1}",
    "nms": [
      {
        "id": 1,
        "nm": "HI-BI Event Types"
      }
    ]
  },
  "time": {
    "vl": "1970-01-01T00:00:00+00",
    "vle": "1970-01-01T00:00:00+00"
  },
  "locality": {
    "id": 1,
    "dtl": {
        "vl": "",
        "gps": "",
        "region": ""
    }
  },
  "countries": {
    "ids": "{0,1}",
    "nms": [
      {
        "id": 1,
        "nm": "HI-BI Countries"
      }
    ]
  },
  "clsfrs": {
    "ids": "{2}",
    "nms": [
        {
          "id": 2,  
          "nm": "HI-BI Information Themes Сlassifier"
        }
    ]
  },
  "vid": 1,
  "tp": 0,
  "vl": -7777777,
  "svl": "",
  "igrp": true,
  "icln": true,
  "inw": true,
  "ich": false,
  "isDlt": false,
  "msrs": [
    {
      "vid": 10,
      "nm": "HI-BI General Values Event name",
      "lbl": "HI-BI General Values Event name",
      "tp": 1,
      "vl": -7777777,
      "svl": "",
      "icln": false,
      "inw": true,
      "ich": false,
      "isDlt": false
    },
    {
      "vid": 13,
      "nm": "HI-BI General Values Is Important",
      "lbl": "HI-BI General Values Is Important",
      "tp": -1,
      "vl": 0,
      "svl": "",
      "inw": true,
      "ich": false,
      "icln": false,
      "isDlt": false
    },
    {
      "vid": 11,
      "nm": "HI-BI General Values Event short HTML",
      "lbl": "HI-BI General Values Event short HTML",
      "tp": 64,
      "vl": -7777777,
      "svl": "",
      "icln": false,
      "inw": true,
      "ich": false,
      "isDlt": false
    },
    {
      "vid": 12,
      "nm": "HI-BI General Values Event HTML",
      "lbl": "HI-BI General Values Event HTML",
      "tp": 64,
      "vl": -7777777,
      "svl": "",
      "icln": false,
      "inw": true,
      "ich": false,
      "isDlt": false
    },
    {
      "vid": 6,
      "nm": "HI-BI General Values Link Items",
      "lbl": "",
      "tp": 4,
      "vl": -7777777,
      "svl": "",
      "icln": true,
      "inw": true,
      "ich": false,
      "isDlt": false,
      "clctn": []
    },
    {
      "vid": 7,
      "nm": "HI-BI General Values File Items",
      "lbl": "",
      "tp": 8,
      "vl": -7777777,
      "svl": "",
      "icln": true,
      "inw": true,
      "ich": false,
      "isDlt": false,
      "clctn": []
    },
  ]
}

export default EventItem
