    <template>

  <div class="ev-page-main__container" v-if="render">
    <div class="ev-page-main__wrapper">

      <div class="ev-page-main__view" v-if="renderComponent">

        <div class="ev-article-view">
          <div class="ev-article-view__date">
            <div >
              <strong>{{ dayDay }}</strong>&nbsp; {{ dayWeekday }} &nbsp; <b>{{ dayMonth }}</b> &nbsp; <b>{{ dayYear }}</b>
            </div>
            <div style="float:right;">
              {{ getRestDate() }}
            </div>
          </div>

          <div class="ev-article-view__classes">
            <span class="ev-event-class">
              {{ getTypeEvent() }}
            </span>
            <span class="ev-event-class">
              - {{ getCountries() }}
            </span>
            <span class="ev-event-class">
              - {{ getClsfrs() }}
            </span>
          </div>

          <div class="ev-article-view__name">
            <strong>
              {{ eventName().svl }} 
            </strong>
          </div>

          <div class="ev-article-view__field">
            <div class="ev-options__value-contaier">
              <label for="scales">Important:</label> 
              <span>
                <input v-model="imprtnt" type="checkbox" id="scales" name="isImprtnt" checked :disabled="true"/>
              </span>
            </div>
          </div>

          <div class="ev-article-view__field">
            <div class="ev-options__value-contaier">
              <span>{{ getLocality() }}</span>
            </div>
          </div>
          
          <div class="ev-article-view__field">
            <div class="ev-options__value-contaier">
              <span>{{ getTimeDuration() }} {{ getTimeZone() }}</span>
            </div>
          </div>

          <div v-if="links.length > 0 || files.length > 0" class="ev-measure-group-select-container">
            <div class="ev-measure-group-select-controls-light">
              <span v-if="isHideMsrsGroupControl()" class="ev-button-measure-group-control-light" @click="toggleMsrsGroupControl()" title="Show key info">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_tHEC)">
                  <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
                  <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_tHEC">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </span>
              <span v-else class="ev-button-measure-group-control-light" @click="toggleMsrsGroupControl()" title="Hide key info">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_4)">
                  <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
                  <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_4">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </span>
              <span v-if="links.length > 0">
                Links &nbsp;  
              </span>
              <span v-if="files.length > 0">
                Files
              </span>              
            </div>

            <div v-if="!isHideMsrsGroupControl()" class="ev-measure-group-items">
              <LinksCell :links="getLinks()" v-if="links.length > 0"/>
              <FilesCell :files="getFiles()" v-if="files.length > 0"/>
            </div>

          </div>
        
          <div class="ev-article-view__field">
            <Editor :modelValue="shortHtmlItem()" :elId="eventId" :mode="mode" :height="heightShortText"/>
          </div>

          <div class="ev-article-view__field">
            <Editor :modelValue="htmlItem()" :elId="eventId" :mode="mode" :height="heightLongText"/>
          </div>

          <button v-if="auth.interfaces != 0" class="ev-button-edit" @click="editEventItem">
            Edit
          </button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import emitter from '@/emitter.js'

import LinksCell from '@/components/links/links-cell.vue'
import FilesCell from '@/components/files/files-cell.vue'

import 'vue3-date-time-picker/dist/main.css'
import Editor from '@/components/editor/editor.vue'
import {style_html} from '@/components/editor/formatHtml.js'

export default {
  name: 'EventUpload',
  props: ['eventId'],
  components: {
    LinksCell, FilesCell, 
    Editor
  },
  data() {
    const classesMap = new Map();
    
    return {
      render: false,
      renderComponent: true,
      eventTimeRTF: null, 
      dayWeekday: '',
      dayDay: '',
      dayMonth: '',
      dayYear: '',
      timeZone: '',

      isSaving: false,
      newEvent: this.eventId === 0,
      editEvent: this.eventId > 0,
      classes: classesMap,

      themeOptions: {},
      filter: {},
      item: null,
      links: [],
      files: [],

      imprtnt: false,
      
      mode: 'view',
      chldShortText: {
        editor: null
      },
      chldLongText: {
        editor: null
      },
      heightShortText: 288,
      heightLongText: 288,
    }
  },

  computed: {
    ...mapState({
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2,
      auth: state => state.auth
    }),

  },

  async created () {
    try {

      this.classes.set('time', this.classesOptions.get('time').eventUpload_classOptions)
      this.classes.set('eventTypes', this.classesOptions.get('eventTypes').eventUpload_classOptions)
      this.classes.set('countries', this.classesOptions.get('countries').eventUpload_classOptions)
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').eventUpload_classOptions)


      this.themeOptions = this.themesOptions.get('event').eventUpload_themeOptions
      
      this.filter = this.themeOptions.filter

      let payload = {}
      payload.eventId = this.eventId
      payload.themeType = this.themeOptions.themeType
      payload.themeName = this.themeOptions.themeName

      await this.$store.dispatch('getThemeItem', payload)

      this.item = this.themesOptions.get('event').uploadData[0]

      this.eventTimeRTF = new Intl.DateTimeFormat('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
        hour12: false,
        timeZone: this.item.time.tz
      })

      this.filter.time.tz = this.item.time.tz

      this.timeZone = this.item.time.tz

      let time
      time = this.item.time
      time.vl = moment.tz(time.vl, time.tz).format()
      time.vle = moment.tz(time.vle, time.tz).format()

      const d = new Date(this.item.time.vl)

      this.dayWeekday = this.eventTimeRTF.formatToParts(d).find((el) => (el.type === 'weekday')).value, //.toUpperCase(),
      this.dayDay = this.eventTimeRTF.formatToParts(d).find((el) => (el.type === 'day')).value,
      this.dayMonth = this.eventTimeRTF.formatToParts(d).find((el) => (el.type === 'month')).value,
      this.dayYear = this.eventTimeRTF.formatToParts(d).find((el) => (el.type === 'year')).value

      const events = this.item.events.ids.substring(1,this.item.events.ids.length-1).split(',')
      const eventsFilter = this.classes.get('eventTypes').filter
      eventsFilter.vl = []
      for (let i = 1; i < events.length; i++) {
        eventsFilter.vl.push(Number(events[i]))
      }
      eventsFilter.options.multiple = true

      const countries = this.item.countries.ids.substring(1,this.item.countries.ids.length-1).split(',')
      const countriesFilter = this.classes.get('countries').filter
      countriesFilter.vl = []
      for (let i = 1; i < countries.length; i++) {
        countriesFilter.vl.push(Number(countries[i]))
      }
      countriesFilter.options.multiple = true

      const clsfrs = this.item.clsfrs.ids.substring(1,this.item.clsfrs.ids.length-1).split(',')
      const clsfrsFilter = this.classes.get('classHibiInfoItems').filter
      clsfrsFilter.vl = []
      for (let i = 1; i < clsfrs.length; i++) {
        clsfrsFilter.vl.push(Number(clsfrs[i]))
      }
      clsfrsFilter.options.multiple = true


      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }

      if (this.getImprtnt().vl == 1) {
        this.imprtnt = true  
      } else {
        this.imprtnt = false
      }

      const linkClctn = this.item.msrs.filter((item) => (item.vid == 6))[0].clctn
      linkClctn.forEach( (item) => {
        let link = {};
        
        link.isSave = true
        link.isNew = false
        link.file = null
        link.url = item.svl                        
        link.name = item.lbl.name
        link.uploaded = item.lbl.uploaded
        link.checked = false;
        link.id = item.rng

        this.links.push( link)
      })

      const fileClctn = this.item.msrs.filter((item) => (item.vid == 7))[0].clctn
      fileClctn.forEach( (item) => {
        let file = {};
        
        file.isSave = true
        file.isNew = false
        file.file = null
        file.url = item.svl                        
        file.name = item.lbl.name
        file.uploaded = item.lbl.uploaded
        file.checked = false;
        file.id = item.rng

        this.files.push( file)
      })

      //console.log('created this.item, this.filter: ', this.item, this.filter)
      this.render = true

    } catch (error) {
      console.log('created eventsItem upload error:', error)
    } finally {
      for (let cls of this.classes.values()) {
        emitter.all.delete(cls.filter.event)
        emitter.on(cls.filter.event, () => {
          this.handleFilter (cls)
        })
      }

      //this.forceRerender()
    }
  },
  methods: {
    getRestDate () {
      const crDt = Date.now()
      const plnDt = Date.parse(this.item.time.vl)
      const rstDt = plnDt - crDt
      const rstDy = Math.ceil(rstDt / 86400000)
      const rstWk = Math.floor(rstDy / 7)
      if (rstDy >= 0) {
        if (rstDy === 0) {
          return 'Today'
        } else {
          if (rstWk > 0) {
            return rstWk + ' W (' + rstDy + ' d) ->'
          } else {
            return rstDy + ' d ->'
          }
        }
      } else {
        if (rstWk < -1) {
            return (-rstWk-1) + ' W (' + (-rstDy) + ' d) <- Past'
          } else {
            return (-rstDy) + ' d <- Past'
          }
      }
    },
    
    getTypeEvent () {
      const classes = this.item.events.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getCountries () {
      const classes = this.item.countries.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getClsfrs () {
      const classes = this.item.clsfrs.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },

    isHideControl() {
      if (!this.themeOptions.view) {
        return true
      } else {
        return false 
      }
    },
    toggleHideControl () {
      this.themeOptions.view = !this.themeOptions.view
    },

    isHideMsrsGroupControl() {
      if (!this.themeOptions.msrsGroup.view) {
        return true
      } else {
        return false 
      }
    },
    toggleMsrsGroupControl () {
      this.themeOptions.msrsGroup.view = !this.themeOptions.msrsGroup.view
    },

    isHideTextsControl() {
      if (!this.themeOptions.texts.view) {
        return true
      } else {
        return false 
      }
    },
    toggleTextsControl () {
      this.themeOptions.texts.view = !this.themeOptions.texts.view
    },

    classView (className) {
      return this.classes.get(className).view
    },

    eventName () {
      return this.item.msrs.find( (itm) => itm.vid == 10)
    },
    shortHtmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == 11)
    },
    htmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == 12)
    },
    getLinks () {
      return this.links
    },
    getFiles () {
      return this.files
    },
    getImprtnt () {
      return this.item.msrs.find( (itm) => itm.vid == 13)
    },
    getTime () {
      const start = this.item.time.vl.substring(11,16)
      const end = this.item.time.vle.substring(11,16)
      if (start === '00:00' && end === '00:00') {
        return ''
      } else {
        return start + ' - ' + end
      }
    },
    getDuration () {
      if (this.getTime() === '') {
        return ''
      }

      let start = new Date(this.item.time.vl)
      let end = new Date(this.item.time.vle)
      let drtn = end - start
      if (drtn < 3600000) {
        return ', ' + drtn/1000/60 + 'min'
      } else {
        if (drtn % 3600000 > 0) {
          return ', ' + ~~(drtn/1000/60/60) + 'h ' + (drtn % 3600000)/1000/60 + 'min'  
        } else {
          return ', ' + drtn/1000/60/60 + 'h'
        }
      }
    },
    getTimeDuration () {
      const start = this.item.time.vl.substring(11,16)
      const end = this.item.time.vle.substring(11,16)
      if (start === '00:00' && end === '00:00') {
        return ''
      } else {
        if (start === end) {
          return start
        } else {
          const sTime = start + ' - ' + end + ', '
          let dStart = new Date(this.item.time.vl)
          let dEnd = new Date(this.item.time.vle)
          let dDrtn = dEnd - dStart

          if (dDrtn < 3600000) {
            return sTime + dDrtn/1000/60 + 'min'
          } else {
            if (dDrtn % 3600000 > 0) {
              return sTime +  ~~(dDrtn/1000/60/60) + 'h ' + (dDrtn % 3600000)/1000/60 + 'min'  
            } else {
              return sTime + dDrtn/1000/60/60 + 'h'
            }
          }

        }
      }

    },
    getTimeZone () {
      if (this.getTime() === '') {
        return ''
      }

      return '(UTC ' + this.item.time.vle.substring(19,26) + ' ' + this.item.time.tz + ')'
    },
    getLocality () {
      return this.item.locality.dtl.vl + ' ' + this.item.locality.dtl.region  + ' ' + this.item.locality.dtl.gps
    },



    outputHtml () {
      this.shortHtmlItem().svl = style_html( this.chldShortText.editor.getHTML(), {
        'indent_size': 2,
        'indent_char': ' ',
        'max_char': 78,
        'brace_style': 'expand',
        'unformatted': ['a', 'sub', 'sup', 'b', 'i', 'u', 's', 'span', 'pre', 'code']
      })
      this.htmlItem().svl = style_html( this.chldLongText.editor.getHTML(), {
        'indent_size': 2,
        'indent_char': ' ',
        'max_char': 78,
        'brace_style': 'expand',
        'unformatted': ['a', 'sub', 'sup', 'b', 'i', 'u', 's', 'span', 'pre', 'code']
      })
    },

    async setFilter () {
      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }
    },

    handleFilter (cls) {

      switch (cls.className) {

        case 'eventTypes':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.event = ''
              this.item.events.ids = ''
              if ( Array.isArray(cls.filter.vl) ) {
                this.item.events.ids = '0,'
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  if (i == 0) {
                    this.item.event.id = cls.filter.vl[i]
                  }
                  this.filter.event = this.filter.event + cls.filter.vl[i] + ','
                  this.item.events.ids = this.item.events.ids + cls.filter.vl[i] + ','
                
                }
                this.filter.event = this.filter.event.substring(0, this.filter.event.length-1)
                this.item.events.ids = this.item.events.ids.substring(0, this.item.events.ids.length-1)

              } else {
                this.filter.event = cls.filter.vl.toString()
                this.item.event.id = cls.filter.vl
                this.item.events.ids = cls.filter.vl.toString()
              
              }
              this.filter.event = '{' + this.filter.event + '}'
              this.item.events.ids = '{' + this.item.events.ids + '}'
            
            } else {
              this.filter.event = '{}'
              this.item.event.id = 1
              this.item.events.ids = '{0,1}'  
            }
          
          } else {
            this.filter.event = '{0}'
          }
          
          //console.log('this.filter.event, this.item.events: ', this.filter.event, this.item.event, this.item.events)
        
        break;

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              this.item.countries.ids = ''
              if ( Array.isArray(cls.filter.vl) ) {
                this.item.countries.ids = '0,'
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  if (i == 0) {
                    this.item.locality.id = cls.filter.vl[i]
                  }
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                  this.item.countries.ids = this.item.countries.ids + cls.filter.vl[i] + ','
                
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
                this.item.countries.ids = this.item.countries.ids.substring(0, this.item.countries.ids.length-1)

              } else {
                this.filter.locality = cls.filter.vl.toString()
                this.item.locality.id = cls.filter.vl
                this.item.countries.ids = cls.filter.vl.toString()
              
              }
              this.filter.locality = '{' + this.filter.locality + '}'
              this.item.countries.ids = '{' + this.item.countries.ids + '}'
            
            } else {
              this.filter.locality = '{}'
              this.item.locality.id = 1
              this.item.countries.ids = '{0,1}'  
            }
          
          } else {
            this.filter.event = '{0}'
          }

          //console.log('this.filter.locality, this.item.countries: ', this.filter.locality, this.item.locality, this.item.countries)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              this.item.clsfrs.ids = ''
              if ( Array.isArray(cls.filter.vl) ) {
                this.item.clsfrs.ids = '0,'
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                  this.item.clsfrs.ids = this.item.clsfrs.ids + cls.filter.vl[i] + ','
                
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
                this.item.clsfrs.ids = this.item.clsfrs.ids.substring(0, this.item.clsfrs.ids.length-1)
              
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
                this.item.clsfrs.ids = cls.filter.vl.toString()
              
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
              this.item.clsfrs.ids = '{' + this.item.clsfrs.ids + '}'
            
            } else {
              this.filter.clsfrs = '{}'
              this.item.clsfrs.ids = '{0,1}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs, this.item.clsfrs: ', this.filter.clsfrs, this.item.clsfrs)
        
        break;
      }

      //console.log('handleUploadFilter: ', cls)
    },

    getInitalUploadEventItem () {
      this.$store.dispatch('getInitalUploadThemeItem', this.themeOptions)
    },

    editEventItem () {
      emitter.emit('editEvent', {id: this.eventId})
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

  }
}
</script>

<style lang="scss" scoped>
//@import '@/assets/events.scss';
@import '@/assets/events-view.scss'
</style>
