<template>

  <!--New-->
  <div v-if="auth.interfaces != 0 && viewMode == 0" class="kn-button-add" @click="newItem">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="16"></line>
      <line x1="8" y1="12" x2="16" y2="12"></line>
    </svg>
  </div>

  <div class="kn-page">

    <div class="nw-page-main__side">
    </div>

    <main v-if="renderComponent">
      <h1 class="nm-head">
        HI-BI Knowledge
      </h1>

      <div v-if="viewMode == 0">
        <KnowledgeList />
      </div>

      <div v-if="viewMode > 0">
        <KnowledgeItem :isInitialEdit="isEdit" :knowledgeId="knowledgeId" />
      </div>

    </main>


    <div class="nw-page-main__side">
    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import { useHead } from '@vueuse/head'
import KnowledgeList from '@/components/knowledge/KnowledgeList.vue';
import KnowledgeItem from '@/components/knowledge/KnowledgeItem.vue';

export default {
  name: 'Knowledge',
  components: { 
    KnowledgeList,
    KnowledgeItem,
  },
  data () {
    return {
      renderComponent: true,

      viewMode: 0,  //0 - List, 1 - Item
      isEdit: false,
      knowledgeId: 0
    }
  },

  beforeCreate () {

    useHead(
      {
        title: 'Aiviron HI-BI',
        link: [
          {
            rel: 'canonical',
            href: 'https://www.aiviron.com/knowledge/all'
          },
          {
            rel: 'icon',
            href: '/favicon.ico'
          }
        ],
        meta: [
          { name: 'description', content: 'HI-BI. База знаний личной и деловой жизни: управление, знания, информационные технологии, спорт (спортивное ориентирование, бег)'},

          { property: 'og:locale', content: 'ru-ru'},
          { property: 'og:type', content: 'article'},
          { property: 'og:title', content: 'HI-BI. База знаний личной и деловой жизни'},
          { property: 'og:description', content: 'Управление, знания, информационные технологии, спорт (спортивное ориентирование, бег)'},
          { property: 'og:url', content: 'https://www.aiviron.com/knowledge/all'},
          { property: 'og:image', content: '/favicon.ico'},
          { property: 'og:site_name', content: 'Aiviron'},
        ],

      }
    )

  },

  async created () {
    try {

      await this.$store.dispatch('set_interfaces')

      this.$watch(
        () => this.$route.fullPath,
        (toParams ) => {
          // react to route changes...
          //console.log('input: ', toParams, previousParams, this.$route);

          switch (toParams) {
            case '/knowledge/all':
              this.viewMode = 0;
              this.isEdit = false;
              this.knowledgeId = 0;
              this.forceRerender();
              
              break;
          
            case '/knowledge/new':
              this.viewMode = 1;
              this.isEdit = true;
              this.knowledgeId = 0;
              this.forceRerender();
              
            break;

            default:
              this.viewMode = 1;
              this.isEdit = false;
              this.knowledgeId = parseInt(this.$route.params.id);

              break;
          }
          
        }
      )

      const paramsId = this.$router.currentRoute._value.params.id;

      switch (paramsId) {
        case 'all':
          this.viewMode = 0;
          this.isEdit = false;
          this.knowledgeId = 0;
          
          break;
        case 'new':
          this.viewMode = 1;
          this.isEdit = true;
          this.knowledgeId = 0;
          
          break;
      
        default:
          this.knowledgeId = parseInt(paramsId);    
          if (this.knowledgeId > 0) {
            this.viewMode = 1;
            this.isEdit = false;
          } else {
            this.viewMode = -1;
          }

          break;
      }
      
    } catch (error) {
      console.error('Knowledge created error:', error)
      
    }

  },

  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },

  methods: {

    newItem () {
      this.$router.push('/knowledge/new');
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

  }

}
</script>

<style lang="scss" scoped>
  @import '@/assets/knowledge_2.scss';
</style>
