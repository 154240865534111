import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import TelegramBlockComponent from './TelegramBlockComponent.vue'

export default Node.create({
  name: 'TelegramBlockComponent',

  group: 'block',
  atom: true,

  addOptions: {
		HTMLAttributes: {
			class: "telegram"
		}
	},

	addAttributes() {
		return {
			hibiHtml: {
				default: ``,
				parseHTML: element => element.innerHTML,
			},
      beginTag: {
        default: '<hibi-telegram>'
      },
      endTag: {
        default: '</hibi-telegram>'
      }
		}
	},
	parseHTML () {
		return [{
			tag: 'hibi-telegram',
			//tag: 'div[class="twitter-tweet twitter-tweet-rendered"]',
		}]
	},
	renderHTML({HTMLAttributes}) {
		const el = document.createElement('hibi-telegram');
		//el.classList.add('twitter-tweet');
		//const el = document.createElement('div');
		//el.classList.add('twitter-tweet');
		//el.classList.add('twitter-tweet-rendered');
		
		el.innerHTML = HTMLAttributes['hibiHtml'];
		//console.log("HTMLAttributes['value']", HTMLAttributes['hibiHtml'], el)

		return el
	},

  addNodeView() {
    return VueNodeViewRenderer(TelegramBlockComponent)
  },
})