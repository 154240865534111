<template>
  <node-view-wrapper as="span" v-if="node.attrs.width !== 0">
    <img
      :src="node.attrs.src"
      :width="node.attrs.width"
      :draggable="node.attrs.isDraggable"
      :data-drag-handle="node.attrs.isDraggable"
      ref="resizableImg" />
  </node-view-wrapper>
  <node-view-wrapper as="span" v-else>
    <img
      :src="node.attrs.src"
      :draggable="node.attrs.isDraggable"
      :data-drag-handle="node.attrs.isDraggable"
      ref="resizableImg" />
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

data() {
  return {
    // When is resizing
    isResizing: false,
    // Original aspect ratio of the img
    aspectRatio: 0,
    lastCientY: -1
  };  
},

mounted() {
  //console.log('start mounted this: ', this.node, this.$refs.resizableImg)
  // When the image has loaded
  
  this.$refs.resizableImg.onload = () => {
    // Aspect Ratio from its original size
    this.aspectRatio = this.$refs.resizableImg.naturalWidth / this.$refs.resizableImg.naturalHeight;
    this.$refs.resizableImg.width = this.node.attrs.width
    //this.$refs.resizableImg.height = this.node.attrs.height
  };

  // On mouse down, start resizing
  this.$refs.resizableImg.addEventListener('mousedown', (e) => {

    // We are not resizing if the img is draggable
    if (this.node.attrs.isDraggable) {
        return;
    }

    this.startResize(e)
  });

},

methods: {
  startResize (e) {

    this.isResizing = true;

    //console.log('startResize1: ', this.$refs.resizableImg.width, this.$refs.resizableImg.height)

    this.node.attrs.width = this.$refs.resizableImg.width
    this.node.attrs.height = this.$refs.resizableImg.height

    this.lastClientY = e.clientY
    
    // On mouse move, resize
    this.$refs.resizableImg.addEventListener('mousemove', this.onMouseMove)
    this.$refs.resizableImg.addEventListener('mouseup', this.stopResize)
  },

  stopResize () {
  
    this.isResizing = false;
    this.lastClientY = -1

    this.node.attrs.width = this.$refs.resizableImg.width
    this.node.attrs.height = this.$refs.resizableImg.height
    
    this.$refs.resizableImg.removeEventListener('mousemove', this.onMouseMove)
    this.$refs.resizableImg.removeEventListener('mouseup', this.stopResize)

    //console.log('stopResize: ', this.$refs.resizableImg.width, this.$refs.resizableImg.height, e)
  },

  onMouseMove (e) {
    const clientY = e.clientY
    
    const diffY = this.lastClientY - clientY
    const diffX = Math.floor(this.aspectRatio * diffY)

    this.$refs.resizableImg.width = this.node.attrs.width + diffX
    this.$refs.resizableImg.height = this.node.attrs.height + diffY

  },

},

}

</script>

<style lang="scss" scoped>

</style>