// import axios from 'axios'
import axios_http from '@/http/index.js'

const state = {
  themesItems: []
}

const mutations = {
  UPDATE_THEMES_ITEMS (state, payload) {
    state.themesItems = payload
  }
}

const actions = {
  async getThemesItems ({ commit }) {
    await axios_http
      .get('/themes')
      .then((response) => {
        commit('UPDATE_THEMES_ITEMS', response.data)
        // console.log(response.data)
      })
      .catch((error) => {
        console.log('GET themes master data call unsuccessful :(', error)
      })
  },
  async updateThemesItems ({ commit }, updatedThemesItems) {
    await axios_http
      .post('/themes', updatedThemesItems)
      .then((response) => {
        commit('UPDATE_THEMES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('Update themes master data call unsuccessful :(', error)
      })
  }
}

const getters = {
  themesItems: state => state.themesItems
}

const themesModule = {
  state,
  mutations,
  actions,
  getters
}

export default themesModule
