import { Extension } from "@tiptap/core"
import "@tiptap/extension-text-style"

const FontSize = Extension.create({
	name: "fontSize",
	
	addOptions() {
		return {
			types: ["textStyle"],
			defaultSize: '16px'
		}
	},
	
	addGlobalAttributes() {
		return [{
			types: this.options.types,
			attributes: {
				fontSize: {
					default: this.options.defaultSize,
					parseHTML: element => 
						element.style.fontSize.replace(/['"]+/g, "") || this.options.defaultSize,
					renderHTML: attributes => {
						if (!attributes.fontSize) {
							return {};
						}

						return {
							style: `font-size: ${attributes.fontSize}`,
						}
					}
				}
			},
		}]
	},

	addCommands() {
		return {
			setFontSize:
				(fontSize) =>
					({ chain }) => {
						return chain().setMark("textStyle", { fontSize }).run();
					},
			unsetFontSize: 
				() => 
				({ chain }) => {
					return chain()
						.setMark("textStyle", { fontSize: null })
						.removeEmptyTextStyle()
						.run();
				}
		}
	},
})

export default FontSize;