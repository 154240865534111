import { Node } from "@tiptap/core"

export const inputRegex = /https:\/\/www.youtube.com\/[a-z?=A-Z\-0-7&_]+/

export default Node.create({
	name: "iframe",
	group: "block",
	addOptions: {
		allowFullscreen: true,
		HTMLAttributes: {
			class: "iframe-wrapper",
		}
	},
	addAttributes() {
		return {
			src: {
				default: null,
			},
			frameborder: {
				default: 0,
			},
      width: {
        deafult: 560,
      },
      height: {
        deafult: 315,
      },
			allowfullscreen: {
				default: this.options.allowFullscreen,
				parseHTML: () => this.options.allowFullscreen,
			},
		}
	},
	parseHTML() {
		return [
			{tag: "iframe"},
			{context: "div/iframe"},
			{consuming: true},
		]
	},
	renderHTML({ HTMLAttributes }) {
		return ["div", this.options.HTMLAttributes, ["iframe", HTMLAttributes]]
	},
	addCommands() {
		return {
			//setIframe: (options) => ({ tr, dispatch }) => {
			//	const { selection } = tr
			//	console.log('setIframe: ', tr, dispatch, options)
			//	const node = this.type.create(options)

			//	if (dispatch) {
			//		tr.replaceRangeWith(selection.from, selection.to, node)
			//	}

			//	return true
			//},

		}
	},
})