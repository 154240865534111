import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'
import { createHead } from "@vueuse/head"
import '@/assets/treeselect.css'

const app = createApp(App)

const head = createHead()
app.use(head)

//const rootComponent = 
app.use(store).use(router).mount('#app')
//console.log(app, rootComponent, 'app.config.unwrapInjectedRef = true')
app.config.unwrapInjectedRef = true


