<template>
  <a v-if="isExternal" class="external-link" :href="to" target="_blank" rel="noopener">
    <slot/> <i class="fas fa-external-link-alt"></i>
  </a>
  <router-link active-class="active-link" exact-active-class="exact-active-link" v-bind="$props"><slot /></router-link>
</template>

<script>
import {RouterLink} from 'vue-router'

export default{
  props: { ...RouterLink.props },
  computed:{
    isExternal(){
      return typeof this.to === 'string' && this.to.startsWith('http')
    }
  }
}
</script>

<style scoped>
.external-link i {
  font-size: 0.8em;
  opacity: 0.7;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

a {
  color: var(--light-color);
}

.active-link {
    color: #FFF;
}

.exact-active-link {
    color: #FFF;
    font-weight: bold;
}

</style>
