function searchTree (el, id) {
  if (el.id === id) {
    return el
  } else if (el.children != undefined) {
    let i
    let rslt = null
    for (i = 0; rslt === null && i < el.children.length; i++) {
      rslt = searchTree(el.children[i], id)
    }
    return rslt
  }
  return null
}

function sortTree (el, id) {
  if (el.children != undefined) {
    el.children.sort(compareAsc)
    let i
    for (i = 0; i < el.children.length; i++) {
      sortTree(el.children[i], id)
    }
  }
}

function compareAsc (value1, value2) {
  if (value1.label < value2.label) {
    return -1
  } else if (value1.label > value2.label) {
    return 1
  } else {
    return 0
  }
}

function fullpathTree (el, fullpath) {
  if (el.children != undefined) {
    let i
    for (i = 0; i < el.children.length; i++) {
      if (fullpath != '') {
        el.children[i].label = el.label + ' │ ' + el.children[i].label
      }

      fullpathTree(el.children[i], el.label)
    }
  }
}

function clearfullpathTree (el, id) {
  if (el.children != undefined) {
    let i
    for (i = 0; i < el.children.length; i++) {
      const l = el.children[i].label.lastIndexOf('│')
      if (l >= 0) {
        el.children[i].label = el.children[i].label.substring(l+2) 
      }

      clearfullpathTree(el.children[i], id)
    }
  }
}


export {searchTree, sortTree, fullpathTree, clearfullpathTree}