// import axios from 'axios'
import axios_http from '@/http/index.js'

const state = {
  channelItems: [],
  channelValue: null,
  upchannelItem: [],
  upchannelValue: null
}

const mutations = {
  UPDATE_CHANNEL_ITEMS (state, payload) {
    if (payload != '') {
      state.channelItems = payload  
      state.channelItems.sort((a,b) => {if (a.time.vl < b.time.vl) {return 1} if (a.time.vl > b.time.vl) {return -1} return 0})
    } else {
      state.channelItems = []
    }
  },
  UPDATE_CHANNEL_VALUE (state, payload) {
    state.channelValue = payload
  },
  UPDATE_UPCHANNEL_ITEM (state, payload) {
    if (payload != '') {
      state.channelItems = payload
    } else {
      state.channelItems = []
    }
  },
  UPDATE_UPCHANNEL_VALUE (state, payload) {
    state.upchannelValue = payload
  }
}

const actions = {

  async filterChannelItems ({ commit }, payload) {
    await axios_http
      .post('/data/filter', payload.filter)
      .then((response) => {
        //console.log('GET /data/filter call successful: ', response.data)
        commit('UPDATE_CHANNEL_ITEMS', response.data)
        commit('UPDATE_CHANNEL_VALUE', payload.value)
      })
  },
  async getChannelItem ({ commit }, payload) {
    // console.log('api/events')
    await axios_http
      //.get('/myhibi/' + selectedValue.toString())
      .post('/data/filter', payload.filter)
      .then((response) => {
        //console.log('GET call api/medcond/ successful: ', selectedValue, response, )
        commit('UPDATE_UPCHANNEL_ITEM', response.data)
        if (response.data !== '') {
          commit('UPDATE_UPCHANNEL_VALUE', payload.value)
        } else {
          commit('UPDATE_UPCHANNEL_VALUE', null)
        }
      })
      .catch((error) => {
        console.log('GET node data call unsuccessful :(', error)
      })
  },
  async uploadChannelItem ({ commit }, dataItem) {
    console.log('channelItem to upload:', dataItem)
    await axios_http
      .post('/data/upload', dataItem)
      .then((response) => {
        console.log('UPDATE_UPCHANNEL_ITEM: ', response.data)
        commit('UPDATE_UPCHANNEL_ITEM', response.data)
      })
      .catch((error) => {
        console.log('POST upload channel data call unsuccessful :(', error)
      })
  }
}

const getters = {
  channelItems: state => state.channelItems,
  channelValue: state => state.channelValue,
  upchannelItem: state => state.upchannelItem,
  upchannelValue: state => state.upchannelValue
}

const channelModule = {
  state,
  mutations,
  actions,
  getters
}

export default channelModule
