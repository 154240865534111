import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import TreeBlockComponent from './TreeBlockComponent.vue'

export default Node.create({
  name: 'TreeBlock',

  group: 'block',

  content: 'block+',

  draggable: true,

  addAttributes() {
    return {
      view: {
        default: 'true',
        parseHTML: (element) => {
          //console.log ('element.getAttribute(view): ', element.getAttribute('view'))
          //element.hidden = true
          return element.getAttribute('view')
        },
        renderHTML: (attributes) => {
					return {
						view: attributes.view
					};
				}
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="tree-item"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'tree-item' }), 0]
  },

  addNodeView() {
    return VueNodeViewRenderer(TreeBlockComponent)
  },
})