<template>
  <div class="ln-link-Info">
    <div class="ln-field">
      <div>
        <input type="checkbox" :checked="link.checked" @change="check()">
      </div>
    </div>

    <div class="ln-field">
      <div class="ln-input-Control">
        {{ link.id }} &nbsp;
        <input type="text" v-model="name" v-on:change="setName()">
        <input type="text" v-model="linkName" v-on:change="setLinkName()">
      </div> 
    </div>

    <div class="ln-field">
    </div>

    <div class="ln-field">
        <span v-html="getLink"/>&nbsp;  
        <span style="font-size:12px"> ({{ link.url }})</span>
    </div>

  </div>
  <hr style="width:100%; height:1px; background: #000"/>

</template>

<script>
import emitter from '@/emitter.js'

export default {
  name: 'LinksUploadItem',
  props: ['link', 'pathFolder', 'pathFile', 'baseUrl'],
  data () {
    return {
      URL: null,
      name: '',
      item: null,
      linkName: ''
    }
  },
  created () {
    if (this.link.url.length > 0) {
      this.name = this.link.name
      this.linkName = this.link.url
    }
  },
  computed: {
    getLink () {
      return '<a href="' + this.link.url + '" target="_blank">' + this.link.name + '</a>'
    }
  },
  methods: {
    check () {
      emitter.emit('check-link-item', {
        id: this.link.id,
        isSave: this.link.isSave
      })
    },
    setName () {
      let wlink = this.link //trick to set this.link.name
      wlink.name = this.name
    },
    setLinkName () {
      let wlink = this.link //trick to set this.link.name
      wlink.url = this.linkName
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/link-upload-group.scss';
</style>
