<template>
  <div class="ln-link-Info">
    <div class="ln-field">
      <div>
        <input type="checkbox" :checked="file.checked" @change="check()">
      </div>
    </div>

    <div class="ln-field">
      <div class="ln-input-Control">
        {{ file.id }} &nbsp;
        <input type="text" v-model="name" v-on:change="setName()">
        <span style="font-size:12px">{{ file.url }}</span>
      </div>
      <br> <br>
    </div>

    <div class="ln-field">
    </div>

    <div class="ln-field" v-if="!file.isSave && !file.uploaded && isFile">
      {{ file.name }}&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:12px">({{ file.url }}) </span>
      <button class="hb-button" @click="openFile">Open file</button>
    </div>
    <div class="ln-field" v-if="!file.isSave && !file.uploaded && !isFile">
      <span v-html="getLink"/> &nbsp;
      <span style="font-size:12px"> ({{ file.url }})</span>
    </div>

    <div class="ln-field" v-if="(file.isSave || file.uploaded) && isFile">
      {{ file.name }}&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:12px">({{ file.url }}) </span>
      <button class="hb-button" @click="openFile">Open file</button>
    </div>
    <div class="ln-field" v-if="(file.isSave || file.uploaded) && !isFile">
      <span v-html="getLink"/> &nbsp;
      <span style="font-size:12px"> ({{ file.url }})</span>
    </div>
    
  </div>
  <hr style="width:100%; height:1px; background: #000"/>

</template>

<script>
import emitter from '@/emitter.js'

export default {
  name: 'FileItem',
  props: ['file', 'pathFolder', 'pathFile', 'baseUrl'],
  data () {
    return {
      URL: null,
      name: '',
      item: null,
      fileName: '',
      pickerOpts: {
/*
        types: [
          {
            description: 'Images',
            accept: {
              'image/*': ['.png', '.gif', '.jpeg', '.jpg']
            }
          },
        ],
        excludeAcceptAllOption: false,
*/        
        multiple: false
      }
    }
  },
  created () {
    if (this.file.url.length > 0) {
      this.name = this.file.name
      this.fileName = this.file.url
    } else {
      this.name = this.file.file.name
      this.fileName = this.name
    }
    
  },
  computed: {
    isFile () {
      // console.log('getFileUrl: ', this.getFileUrl())
      return this.getFileUrl()[1] == ':'
    },
    getLink () {
      return '<a href="' + this.file.url + '" target="_blank">' + this.file.name + '</a>'
    }
  },
  methods: {
    check () {
      emitter.emit('check-file-item', {
        id: this.file.id,
        isSave: this.file.isSave
      })
    },
    getFileUrl () {
      //trick to set this.file
      let witem = this.file
      if  (!this.file.uploaded) {
        witem.url = this.baseUrl + this.pathFolder + this.pathFile + this.fileName 
      }
      witem.name = this.name
      //console.log('witem, file: ', this.file.url, this.file.name)
      return this.file.url
    },
    openFile () {
      try {
        //console.log('open file: ', this.file.url)
        this.$store.dispatch('openFile', this.file.url)
      } catch (error) {
        console.log('Open file error:', error)
      }
    },
    setName () {
      let wfile = this.file //trick to set this.file.name
      wfile.name = this.name
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/link-upload-group.scss'
</style>
