<!--
/**
 * @fileoverview TaskList component
 * @license MIT
 * @author Rafal Pospiech <neuronet.io@gmail.com>
 * @package GanttElastic
 */
-->
<template>
  <div
    class="gantt-elastic__task-list-wrapper"
    ref="taskListWrapper"
    :style="{ ...style['task-list-wrapper'], width: '100%', height: '100%' }"
    v-show="stateOptionsTaskListDisplay"
  >
    <div class="gantt-elastic__task-list" :style="{ ...style['task-list'] }" ref="taskList">
      <TaskListHeader></TaskListHeader>
      <div
        class="gantt-elastic__task-list-items"
        ref="taskListItems"
        :style="{ ...style['task-list-items'], height: stateOptionsRowsHeight + 'px' }"
      >
        <TaskListItem v-for="task in root.visibleTasks" :key="task.id" :task="task"></TaskListItem>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import TaskListHeader from '@/components/gantt/components/TaskList/taskListHeader.vue';
import TaskListItem from '@/components/gantt/components/TaskList/taskListItem.vue';
export default {
  name: 'TaskList',
  components: {
    TaskListHeader,
    TaskListItem
  },
  data() {
    return {};
  },
  setup () {
    const state = inject('state')
    const style = inject('style')
    const root = inject('root')
    return { root, state, style }
  },
  /**
   * Mounted
   */
  mounted() {
    this.state.refs.taskListWrapper = this.$refs.taskListWrapper;
    this.state.refs.taskList = this.$refs.taskList;
    this.state.refs.taskListItems = this.$refs.taskListItems;
  },
  computed: {
    stateOptionsRowsHeight () {
      return this.state.options.rowsHeight
    },
    stateOptionsTaskListDisplay () {
      return this.state.options.taskList.display
    }
  }
};
</script>
