<template>
  <div id="hibicalendar-cell" class="container1">
    <div class="clndr">
      <hibiCalendarDay v-for="day in calendarItems"
        :key="day.id"
        :day="day" />
    </div>
  </div>
</template>

<script>
import hibiCalendarDay from './hibicalendar-day.vue'

export default {
  name: 'hibiCalendarCell',
  data () {
    return {
      eventsItems: this.events,
      eventsDate: []
    }
  },
  props: ['events'],
  components: {
    hibiCalendarDay
  },
  created () {
    // console.log('hibiCalendar-cell created')
  },
  computed: {
    calendarItems () {
      // console.log('computed calendarItems()')
      // create this.eventsDate array and sort by date
      this.eventsDateFilter(this.eventsItems)

      const ar = this.eventsDate

      let evntAr = []

      let dt = ''
      let evntArIndx = -1
      for (let i = 0, ln = ar.length; i < ln; i++) {
        dt = ar[i].time.vl.substring(0, 10)
        evntArIndx = evntAr.findIndex((item) => (item.date === dt))
        if (evntArIndx < 0) {
          evntAr.push({ date: dt, events: [ar[i]] })
        } else {
          evntAr[evntArIndx].events.push(ar[i])
        }
        // console.log(i, evntAr, evntAr.length)
      }
      // console.log('evntAr ', evntAr)
      
      //check current day
      const d = new Date()
      dt = d.toISOString().substring(0, 10)
      evntArIndx = evntAr.findIndex((item) => (item.date === dt))
      if (evntArIndx < 0) {
        evntAr.push({ date: dt, events: [] })
        evntAr = evntAr.sort(this.compareAscDt)
      }

      return evntAr
    }
  },  
  methods: {
    eventsDateFilter () {
      // console.log('eventsDateFilter (array)')
      this.eventsDate = this.eventsItems.sort(this.compareAsc)
    },
    compareAsc (value1, value2) {
      if (value1.time.vl < value2.time.vl) {
        return -1
      } else if (value1.time.vl > value2.time.vl) {
        return 1
      } else {
        return 0
      }
    },
    compareAscDt (value1, value2) {
      if (value1.date < value2.date) {
        return -1
      } else if (value1.date > value2.date) {
        return 1
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#hibicalendar-cell {
  margin-bottom: 50px;
  background-color: #EBEBEB; // #3D6279;
   /*.column {
    padding: 0 0 0 0;
  }*/
}

span { font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

input + label {
  display: inline-block;
  border: 1px solid #A5fe02; /*#999;*/
  /*background: #B4DAd0;*/
  /* color: #3D6279; */
  /* color: #3D0079; */
  color: #000;
  background-color: #57dae2;
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  position: -webkit-sticky;
  position: sticky;
  top: 107px;
}

input:checked + label {
  background: #A5fe02;
  border-bottom: 1px solid transparent;
}

.clndr{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(180px,100%), 1fr));
    gap: 0rem;
    word-wrap: break-word;
    font-size: 12px;
    line-height: 1.4;
    border-radius: 0.5rem;
    background-color:  #FFFFFF; // #A5fe02; /* #3D6279 */
    box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
}
.clndr > .clndr-cell {
    background-color: #EBEBEB; //#B4DAD0;
    padding: 0.25rem;
    border-radius: 0.5rem;
    font-family: Helvetica, Arial, sans-serif;
    margin: 0.1rem;
    font-size: 12px;
    line-height: 1.25;
    box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
}
</style>
