<template>
  <node-view-wrapper class="draggable-item">
    
    <div
        class="drag-handle"
        contenteditable="false"
        draggable="true"
        data-drag-handle
      >
      <div v-if="isHide()" class="hide-handle" @click="toggleHide()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_4)">
          <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="black" stroke-opacity="0.2" stroke-width="2"/>
          <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="black" stroke-opacity="0.2" stroke-width="2"/>
          </g>
          <defs>
          <clipPath id="clip0_1_4">
          <rect width="16" height="16" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <div v-else class="hide-handle" @click="toggleHide()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_4)">
          <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="black" stroke-opacity="0.2" stroke-width="2"/>
          <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="black" stroke-opacity="0.2" stroke-width="2"/>
          </g>
          <defs>
          <clipPath id="clip0_1_4">
          <rect width="16" height="16" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <node-view-content class="content km-tree-top-padding" :class="{ 'is-hide': isHide()}"/>

<!--
    <node-view-content v-show="!isHide()" class="content"/>
-->

</node-view-wrapper>
</template>

<script>
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
  props: nodeViewProps,

  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  
  methods: {
    isHide () {
      if (this.node.attrs.view === 'true') {
        return false
      } else {
        return true
      }
    },

    toggleHide() {
      if (this.node.attrs.view === 'true') {
        this.node.attrs.view = 'false'
        //this.updateAttributes({view: 'false'})
      } else {
        this.node.attrs.view = 'true'
        //this.updateAttributes({view: 'true'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-item {
  display: flex;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background: white;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.2  ),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;

  .drag-handle {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: -0.3rem;
    margin-right: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .hide-handle {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: -0.1rem;
    left: 1.5rem;
    //margin-right: 1.5rem;
    cursor:pointer ;
  }

  .content {
    flex: 1 1 auto;
  }

  .km-tree-top-padding {
    padding-top: 0.5rem;
  }


  .is-hide {
    display: none;
  }
}
</style>