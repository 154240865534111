<template>
  <div class="links-container">

    <div class="ln-container-Controls">
      <div class="ln-item-Control">
        <div class="ln-input-Control">
          <button @click="uploadOpen = true">Get files ...</button>
        </div>
      </div>
    </div>

    <hr>

    <div class="ln-container-Controls">
      <div class="ln-item-Control">
        <div class="ln-input-Control">
          <button :disabled="!isFilePasted" @click="fileUpload">Upload</button>
          <span class="file-area" contenteditable="true" @paste="pasteFile"> Paste a file ... </span>
        </div>
        <div class="ln-input-Control">
          <button :disabled="!isUrlEntered" @click="addFileLink()">Add to files</button>
          <input type="text" placeholder="Enter name ..." v-model="textName">
          <input type="text" placeholder="Enter url ..." v-model="textHref">
        </div>
      </div>
      
    </div>

    <hr>

    <Teleport to="body" v-if="uploadOpen">
      <div class="modal">
        <div class="groupControl">
            <button @click="doApplay()" :disabled="!isSelected">Applay</button>
            <button @click="doSelect()" :disabled="isUploaded">Select Files ...</button>
            <button @click="doUpload()" :disabled="!isSelected || isUploaded">Upload</button>
            <button @click="doCancel()">Cancel</button>
        </div>
        <div class="groupControl">
        </div>
        <FilesUploadCell v-if="isSelected" :files="newFiles" :pathFile="pathFile" :isSavedMode="false" :isUploaded="isUploaded"/>

      </div>

    </Teleport>

    <FilesUploadCell :files="files" :pathFile="emptyPath" :isSavedMode="true" :isUploaded="isUploaded"/>

  </div>
</template>

<script>
import axios_http from '@/http/index.js'
import emitter from '@/emitter.js'
import FilesUploadCell from './files-upload-cell.vue'

export default {
  name: 'FileGroup',
  data () {
    return {
      textHref: '',
      textName: '',
      isFilePasted: false,
      fileCopied: '',
      isUploaded: false,
      isUploding: false,
      uploadOpen: false,
      uploadPercentage: 0,
      active: false,
      count: 0,
      newFiles: [],
      file: null,
      pathFile: '',
      emptyPath: '',
      pickerOpts: {
        types: [
          {
            description: 'Files',
           //accept: {
            //  '*/*': ['.']
            //}
          },
        ],
        excludeAcceptAllOption: false,
        multiple: true
      }

    }
  },
  props: ['files', 'elId', 'prfx'],
  components: {
    FilesUploadCell
  },
  created () {
    //this.getLinks()
    //this.getLinksCount()
    emitter.all.delete('check-file-item')
    emitter.all.delete('delete-file-item')
    emitter.all.delete('upload-file-item')
    emitter.on('check-file-item', (event) => this.checkFileItem(event.id, event.isSave))
    emitter.on('delete-file-item', (event) => this.deleteFileItem(event.mode))
    emitter.on('upload-file-item', () => this.uploadFileItem())

  },
  computed: {
    isUrlEntered () {
      return this.textHref.length > 0
    },
    isSelected () {
      return this.newFiles.length > 0
    },
    getFilesCount () {
      return this.files.length
    },
    getLink () {
      return '<a href="' + this.textHref + '" target="_blank">' + this.textName + '</a>' 
    },
    getAudio () {
      return '<audio controls src="' + this.textHref + '"> </audio> <span>' + this.textName + '</span>' 
    },
    getVideo () {
      return '<video controls src="' + this.textHref + '"> </video> <span>' + this.textName + '</span>' 
    },
  },
  methods: {
    clickPanel () {
      if (!this.active) {
        this.active = true
      } else {
        this.active = false
      }
    },
    checkFileItem (id, isSave) {
      let files = {}
      if (isSave) {
        files = this.files
      } else {
        files = this.newFiles
      }

      const index = files.findIndex((item) => item.id === id)

      if (index >= 0 ) {
        files[index].checked = !files[index].checked
      }
    },
    deleteFileItem (isSavedMode) {
      let files = {}
      if (isSavedMode) {
        files = this.files
      } else {
        files = this.newFiles
      }

      for( let i = 0; i < files.length; i++){ 
        if ( files[i].checked) { 
            files.splice(i, 1); 
            i--; 
        }
      }

      let index = 0
      files.forEach((item) => {
        item.id = index;
        index = index + 1;
      });

    },
    
    pasteFile (pasteEvent) {
      this.textHref = ''
      this.textName = ''
      this.isUploaded = false
      this.isUploading = true
      this.uploadPercentage = 0 

      pasteEvent.preventDefault()

      //console.log('pasteEvent.clipboardData.items: ', pasteEvent.clipboardData, pasteEvent.clipboardData.items[0])

      let item = null;
      for (let i = 0; i < pasteEvent.clipboardData.items.length; i++) {
        if (pasteEvent.clipboardData.items[i].kind.indexOf("file") == -1) continue;
        item = pasteEvent.clipboardData.items[i];
        this.file = item.getAsFile();
        this.isFilePasted = true;
        this.textName = this.file.name
        this.fileCopied = 'file - name: "' + this.file.name + '", type: "' + this.file.type + '"'
        //console.log('this.file: ', this.file)            

      }
    },
    
    async fileUpload () {
      
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('elId', this.prfx + this.elId);
      //console.log('formData: ', formData.get('elId'))

      await axios_http
      .post('/file-upload', 
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            if (this.uploadPercentage === 100){
              this.isUploading = false
            }
          }.bind(this)
        })
      .then((response) => {
        this.textHref = response.data
        //this.textSrc = '<img src="' + response.data + '" alt="" width="100%">' 
        //this.textHref = '<a href="' + response.data + '" target="_blank">' + response.data + '</a>' 
        this.isUploaded = true
        this.isFilePasted = false

        //console.log(response.data)
      })
      .catch((error) => {
        console.log('File Upload unsuccessful :(', error)
      })
      
    },

    uploadFileItem () {
      for( let i = 0; i < this.newFiles.length; i++){ 
        this.uploadm(this.newFiles[i])
      }
    },
    async uploadm (file) {
      
      let formData = new FormData();
      
      formData.append('file', file.file.slice(), file.file.name);
      formData.append('elId', this.prfx + this.elId);
      //console.log('formData: ', formData.get('elId'))

      await axios_http
      .post('/file-upload', 
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            file.url = 'Uploading ... ' + Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ).toString() + '%';
            //this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            //if (this.uploadPercentage === 100){
            //  this.uploading = false
            //}
          }.bind(this)
        })
      .then((response) => {
        file.url = response.data
        //console.log('response.data: ', file.url)
        file.uploaded = true

        //this.forceRerender();
      })
      .catch((error) => {
        console.log('File Upload unsuccessful :(', error)
      })
      
    },
    doSelect () {
      this.getFiles()
    },
    doUpload () {
      emitter.emit('upload-file-item')
      this.isUploaded = true
    },
    doApplay () {
      emitter.emit('applay-file-item', {
        newFiles: this.newFiles  
      })
      this.newFiles = [];
      
      this.uploadOpen = false
      this.isUploaded = false
    },
    addFileLink () {
      const file = {url: this.textHref, name: this.textName}
       emitter.emit('add-file-item', {
        newFile: file
      })
      this.textHref = ''
      this.textName = ''
      this.isUploaded = false
      this.isUploding = false
      this.file = null
      this.isFilePasted = false
      this.fileCopied = ''
      navigator.clipboard.writeText(this.fileCopied);
    },
    doCancel () {
      this.newFiles = [];
      this.uploadOpen = false
      this.isUploaded = false
    },
    async getFiles () {
      
      const dirHandle = await window.showDirectoryPicker()
//      console.log(':', dirHandle)
      this.pickerOpts.startIn = dirHandle
      const fileHandles = await window.showOpenFilePicker(this.pickerOpts)
      //this.pickerOpts.startIn = dirHandle;
      
      if (!fileHandles) {
        // User cancelled, or otherwise failed to open a file.
        return;
      }

      const allFileData = await Promise.all(
        fileHandles.map( async (fileHandle) => {
          const fileData = await fileHandle.getFile()
          return fileData
        })
      )

      // get file contents
      //const fileData = await fileHandles[0].getFile();
      //console.log('fileData: ', fileData);

      // Check if handle exists inside directory our directory handle
      const relativePaths = await dirHandle.resolve(fileHandles[0]);
      //console.log('relativePaths:', relativePaths)

      if (relativePaths === null) {
        // Not inside directory handle
      } else {
        // relativePaths is an array of names, giving the relative path
        let path = '';
        for (const name of relativePaths) {
          path = path + '\\' + name;
          // log each entry
        }
        
        const pstn = path.lastIndexOf('\\')
        this.pathFile = path.substring(0, pstn+1)
        
        //console.log('path: ', path)
        let index = 0
        
        this.newFiles = allFileData.map((item) => {
          let file = {};
          file.isSave = false
          file.isNew = true
          file.file = item
          file.url = ''
          file.name = ''
          file.uploaded = false
          file.checked = false;
          file.id = index
          index = index+1
          //console.log('create index id: ', index)
          return file
        })
        //console.log('this.newFiles: ', this.newFiles); 
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/link-upload-group.scss';
.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
  overflow-y: scroll;

}
.groupControl {
  margin-top: 16px;
}
.groupControl button{
  margin-left: 24px;
}
</style>
