<template>
  <div class="ln-container-Controls">
    <div class="ln-item-Control">
      <div class="ln-input-Control">
        <div>
          <label for="selectAll">Select/Unselect All</label>
          <input type="checkbox" id="selectAll" v-model="checked" @change="check()">
        </div>
        <div>
          <button @click="deleteSelected()">Delete selected</button>
        </div>
      </div>
    </div>

    <div class="ln-item-Control">
      <div class="ln-input-Control">
        <input type="text" placeholder="Enter base URL ..." v-model="baseUrl" :disabled="!isSavedMode && isUploaded">
        <input type="text" v-model="selectedFolderPath" placeholder="Enter selected folder path ..." :disabled="!isSavedMode && isUploaded">
        <button v-if="isSavedMode">Change base URL</button>
      </div>
    </div>
  </div>

  <hr>

  <FileItem v-for="(file, index) in files"
    :key=index
    :file="file"
    :pathFolder="selectedFolderPath"
    :pathFile="pathFile"
    :baseUrl="baseUrl"
  />
</template>

<script>
import emitter from '@/emitter.js'
import FileItem from './files-upload-item.vue'

export default {
  name: 'FilesCell',
  props: ['files', 'pathFile', 'isSavedMode', 'isUploaded'],
  data () {
    return {
      baseUrl: '',
      selectedFolderPath: '',
      checked: false
    }
  },
  components: {
    FileItem
  },
  methods: {
    check () {
      this.files.forEach((item) => item.checked = this.checked)
      //console.log('this.checked: ', this.checked, this.images)
    },
    deleteSelected () {
      emitter.emit('delete-file-item', {
        mode: this.isSavedMode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/link-upload-group.scss';
</style>
