/**
 * @fileoverview Task mixin
 * @license MIT
 * @author Rafal Pospiech <neuronet.io@gmail.com>
 * @package GanttElastic
 */
import { inject } from 'vue';
import emitter from '@/emitter.js';

export default {
  setup () {
    const state = inject('state')
    const style = inject('style')
    const root = inject('root')
    return { root, state, style }
  },

  computed: {
    /**
     * Get view box
     *
     * @returns {string}
     */
    getViewBox() {
      const task = this.task;
      return `0 0 ${task.width} ${task.height}`;
    },

    /**
     * Get group transform
     *
     * @returns {string}
     */
    getGroupTransform() {
      return `translate(${this.task.x} ${this.task.y})`;
    },

    /**
     * Should we display expander?
     *
     * @returns {boolean}
     */
    displayExpander() {
      const expander = this.state.options.chart.expander;
      return expander.display || (expander.displayIfTaskListHidden && !this.state.options.taskList.display);
    }
  },
  methods: {
    /**
     * Emit event
     *
     * @param {string} eventName
     * @param {Event} event
     */
    emitEvent(eventName, event) {
      if (!this.state.options.scroll.scrolling) {
        emitter.emit(`chart-${this.task.type}-${eventName}`, { event, data: this.task });
      }
    }
  }
};
