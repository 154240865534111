<template>
  <div class="main-window">
    <div class="mn-page-main__side">
    </div>

    <main>
      <div class="main-panel">
        <div class="content-all">
          <div class="content-header">
            <p class="title">Welcome</p>

            <p class="title2"><i>Help you work effectively with personal and business information </i></p>

          </div>

          <div class="content-body" >
            <h1 class="chart-header">
              <img src="@/img/HI-BI.png" style="vertical-align:middle" alt="HI-BI logo"
                title="HI-BI system" loading="lazy">
              &nbsp;HI-BI - One system for both hobbies and business
            </h1>
            <div class="content-paragraph">
              <ul><strong><span style="color: #ff0000; font-size: 16px">H</span>
                  </strong>obby 
                  <strong><span style="color: #ff0000; font-size: 16px">I</span>
                  </strong>ntelligence &amp; 
                  <strong><span style="color: #ff0000; font-size: 16px">B</span>
                  </strong>usiness 
                  <strong><span style="color: #ff0000; font-size: 16px">I</span>
                  </strong>ntelligence
                <li>
                  HI-BI is a system  which serves as a single point of work with information and doing business
                </li>
                <li>
                  Maintaining information on topics using their own categories
                </li>
                <li>
                  Quick and intuitive information search
                </li>
                <li>
                  Data access permissions
                </li>
                <li>
                  The system has its own storage, which can be hosted on a local computer or on the Internet
                </li>
                <li>
                  Adapting the interface and functionality to your needs
                </li>
              </ul>
            </div>
            <div class="content-paragraph">
              <div class="content-quote">
                I like to make apps that effectively help solve cases and problems and achieve personal goals
              </div>
            </div>
          </div> 
        </div>
      </div>
    </main>
    
    <div class="mn-page-main__side">
    </div>
  </div>
</template>

<script>
  
export default {
  data() {
    return {
    }
  },
  async created () {
    try {
      
      await this.$store.dispatch('set_interfaces')
      
    } catch (error) {
      console.log('set_interfaces error:', error)
    } 
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/main.scss';
</style>
  