<template>
  <div class="ln-link-Info">
    <div class="ln-field">
      <div>
        <input type="checkbox" id="selectAllImages" :checked="image.checked" @change="check()">
      </div>
    </div>

    <div class="ln-field">
      <div class="ln-input-Control">
        {{ image.id }} &nbsp;
        <input type="text" v-model="name" v-on:change="setName()">
        <input type="text" v-model="author" v-on:change="setAuthor()">
      </div>
      <br> <br>
    </div>

    <div class="ln-field">
    </div>

    <div class="ln-field" v-if="!image.isSave && !image.uploaded">
      <div v-html="imageFile()"/>
      <span style="font-size:12px">{{ image.url }}</span>
    </div>

    <div class="ln-field" v-if="image.isSave || image.uploaded">
      <div v-html="imageUrl()"/>
      <span style="font-size:12px">{{ image.url }}</span>
    </div>

  </div>
  <hr style="width:100%; height:1px; background: #000"/>

</template>

<script>
import emitter from '@/emitter.js'

export default {
  name: 'ImagesUploadItem',
  props: ['image', 'pathFolder', 'pathFile', 'baseUrl'],
  data () {
    return {
      URL: null,
      name: '',
      author: ''
    }
  },
  created () {
    if (this.image.url.length > 0) {
      this.name = this.image.name
    } else {
      this.name = this.image.file.name
    }
    this.author = this.image.author
  },
  methods: {
    imageUrl () {
      // http://localhost:30004\Tutorial\Системный анализ\Web development
      // 
      //console.log('<img src="' + this.image.url + '" style="width: 120px"/>')
      return '<img src="' + this.image.url + '" style="width: 120px"/>'
    },
    imageFile () {
      // \Tutorial\Системный анализ\Web development
      // http://localhost:30004
      // 
      //this.image.url = this.baseUrl + this.pathFolder + this.pathFile + this.image.file.name
      let wimage = this.image //trick to set this.image.url
      if  (!this.image.uploaded) {
        wimage.url = this.baseUrl + this.pathFolder + this.pathFile + this.image.file.name
      }
      wimage.name = this.name
      wimage.author = this.author
      //console.lsog ('wimage.url, image.url: ', wimage.url, this.image.url)
      //console.log('<img src="' + this.image.url + '" style="width: 120px"/>')
      return '<img src="' + this.image.url + '" style="width: 120px"/>'
    },
    check () {
      emitter.emit('check-image-item', {
        id: this.image.id,
        isSave: this.image.isSave
      })
    },
    setName () {
      let wimage = this.image //trick to set this.image.name
      wimage.name = this.name
    },
    setAuthor () {
      let wimage = this.image //trick to set this.image.author
      wimage.author = this.author
    }

  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/link-upload-group.scss';
</style>
