import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import YoutubeBlockComponent from './YoutubeBlockComponent.vue'

export default Node.create({
  name: 'YoutubeBlockComponent',

  group: 'block',
  atom: true,
  draggable: true,

  addOptions: {
		HTMLAttributes: {
			class: "youtube"
		}
	},

	addAttributes() {
		return {
			hibiHtml: {
				default: ``,
				parseHTML: element => element.innerHTML,
			},
      beginTag: {
        default: '<hibi-youtube>'
      },
      endTag: {
        default: '</hibi-youtube>'
      }
		}
	},
	parseHTML () {
		return [{
			tag: 'hibi-youtube',
			//tag: 'div[class="twitter-tweet twitter-tweet-rendered"]',
		}]
	},
	renderHTML({HTMLAttributes}) {
		const el = document.createElement('hibi-youtube');
		//el.classList.add('twitter-tweet');
		//const el = document.createElement('div');
		//el.classList.add('twitter-tweet');
		//el.classList.add('twitter-tweet-rendered');
		
		el.innerHTML = HTMLAttributes['hibiHtml'];
		//console.log("HTMLAttributes['value']", HTMLAttributes['hibiHtml'], el)

		return el
	},

  addNodeView() {
    return VueNodeViewRenderer(YoutubeBlockComponent)
  },
})