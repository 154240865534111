// import axios from 'axios'
import axios_http from '@/http/index.js'

const state = {
  eventtypesItems: []
}

const mutations = {
  UPDATE_EVENTTYPES_ITEMS (state, payload) {
    state.eventtypesItems = payload
  }
}

const actions = {
  async getEventTypesItems ({ commit }) {
    await axios_http
      .get('/eventtypes')
      .then((response) => {
        commit('UPDATE_EVENTTYPES_ITEMS', response.data)
        // console.log(response.data)
      })
      .catch((error) => {
        console.log('GET eventtypes master data call unsuccessful :(', error)
      })
  },
  async updateEventTypesItems ({ commit }, updatedEventTypesItems) {
    await axios_http
      .post('/eventtypes', updatedEventTypesItems)
      .then((response) => {
        commit('UPDATE_EVENTTYPES_ITEMS', response.data)
      })
      .catch((error) => {
        console.log('Update eventtypes master data call unsuccessful :(', error)
      })
  }
}

const getters = {
  eventtypesItems: state => state.eventtypesItems
}

const eventtypesModule = {
  state,
  mutations,
  actions,
  getters
}

export default eventtypesModule
