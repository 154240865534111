<script setup>
  import { ref } from 'vue';
  import 'lazysizes'
  //import imagesCarouselVue from './images-carousel.vue';
  //import imagesThumbsVue from './images-thumbs.vue';
  //  optimizeDeps: { exclude: ["swiper/vue", "swiper/types"], },

  import imagesCarouselThumbsVue from '@/components/images/images-carousel-thumbs.vue';
  
  //import 'swiper'

  const crsl=ref(false)
  const sld=ref(0)
  //const thmbs=ref(null)

</script>

<template>
  <div class="images-container">
    <div class="image-pnl">

      <div class="cell">
<!--
        <div v-for="n in 3" 
          :key="n" class="cellItem" @click="crsl = true; sld = Number(n)-1">
          <img :data-src="'https://picsum.photos/1024/600?random='+n" class="lazyload cellItemImage"/>
        </div>
-->
        <div v-for="(item, index) in images" 
          :key="index" class="cellItem" @click="crsl = true; sld = Number(index)"
        >
          <img :data-src="item.url" class="lazyload cellItemImage"/>
        </div>

      </div>

      <teleport to="body">
        <div class="carousel" v-show="crsl">
          <div class="carouselDialog">
            <div class="carouselContent">
              <div calss="carouselHeader">
                <span></span>
                <span class="carouselClose" @click.stop="crsl = false">&times;</span>
              </div>
              <div class="carouselBody">
                <!--<imagesCarouselVue :currentSlide="sld" :thumbs="thmbs"/>-->
                <imagesCarouselThumbsVue :images="images" :currentSlide="sld" />
                <!--<imagesCarouselVue />-->
              </div>
              <div class="carouselFooter">
                <!--<imagesThumbsVue @thumbs="thmbs" :currentSlide="sld"/>-->
              </div>

            </div>

          </div>
        </div>
      </teleport>

    
    </div>
  </div>

</template>

<script>

  export default {
    name: 'ImagesCell',
    props: ['images'],
  }

</script>

<style lang="scss" scoped>
  .image-pnl {
    gap: 0.25rem;
    word-wrap: break-word;
    font-size: 16px;
    line-height: 1; // 1.4;
    border-radius: 0.5rem;
    background-color:  var(--light-color);
    align-items:center;
    border-style: solid;
    border-color: var(--light-color) #FFFFFF #FFFFFF #FFFFFF;
  }
.cell {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px,1fr));
  grid-auto-rows: max-content;
  grid-auto-flow: dense;
  place-items: center;
  gap: 10px;
}
.cellItem{
  width: auto;
}
.cellItemImage{
  width: 100%;
  height: 100%;
  min-height: 180px;
  object-fit: cover;
  background-color: rgba(0, 0, 0, .375);
  cursor: pointer;
}
.carousel{
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
}
.carouselDialog{
  position: relative;
  z-index: 101;
  margin: auto;
  padding: 0;
  width: inherit;
  height: inherit;
}
.carouselContent{
  position: relative;
  z-index: 102;
  overflow: auto;
  width: inherit;
  height: inherit;
}
.carouselHeader{
  padding: 10px;
  width: calc(100vw - 30px);
  position: absolute;
  z-index: 104;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.carouselBody{
  position: relative;
  z-index: 103;
}
.carouselFooter{
  padding: 10px;
  width: calc(100vw - 30px);
  position: absolute;
  z-index: 104;
  bottom: 0;
  left: 0;
  /* display: flex;
  justify-content: space-between;*/
}
.carouselClose{
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.carouselClose svg,
.carouselClose i,
.carouselClose img {
  pointer-events: none;
}
.carouselClose:hover,
.carouselClose:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}
</style>

