<template>

  <div class="links-container">

    <div class="ln-container-Controls">
      <div class="ln-item-Control">
        <div class="ln-input-Control">
          <button @click="uploadOpen = true">Get images ...</button>
        </div>
      </div>
    </div>

    <hr>

    <div class="ln-container-Controls">
      <div class="ln-item-Control">
        <div class="ln-input-Control">
          <label for="link">Link: </label> 
          <input type="text" id="link" v-model="image.url">
        </div>
        <div class="ln-input-Control">
          <button @click="addImageLink()">Add to images</button>
        </div>
      </div>
      <div class="ln-item-Control">
        <div class="ln-input-Control">
          <label for="name">Name: </label>
          <input type="text" id="name" v-model="image.name">
        </div>
        <div class="ln-input-Control">
          <label for="author">Author: </label>
          <input type="text" id="author" v-model="image.author">
        </div>
      </div>          
    </div>

    <hr>

    <Teleport to="body" v-if="uploadOpen">
      <div class="modal">
        <div class="groupControl">
            <button @click="doApplay()" :disabled="!isSelected">Applay</button>
            <button @click="doSelect()" :disabled="isUploaded">Select images ...</button>
            <button @click="doUpload()" :disabled="!isSelected || isUploaded">Upload</button>
            <button @click="doCancel()">Cancel</button>
        </div>
        <div class="groupControl">
        </div>
        <ImagesUploadCell v-if="isSelected" :images="newImages" :pathFile="pathFile" :isSavedMode="false" :isUploaded="isUploaded"/>

      </div>

    </Teleport>

    <ImagesUploadCell :images="images" :pathFile="emptyPath" :isSavedMode="true" :isUploaded="isUploaded"/>

  </div>
</template>

<script>
//import ImagesItem from './images-upload-item.vue'
import axios_http from '@/http/index.js'
import emitter from '@/emitter.js'
import ImagesUploadCell from './images-upload-cell.vue'

export default {
  name: 'LinkGroup',
  data () {
    return {
      isUploaded: false,
      uploadOpen: false,
      active: false,
      count: 0,
      newImages: [],
      image: {
        url: '',
        name: '',
        author: ''
      },
      pathFile: '',
      emptyPath: '',
      pickerOpts: {
        types: [
          {
            description: 'Images',
            accept: {
              'image/*': ['.png', '.gif', '.jpeg', '.jpg']
            }
          },
        ],
        excludeAcceptAllOption: false,
        multiple: true
      }

    }
  },
  props: ['images', 'elId', 'prfx'],
  components: {
    ImagesUploadCell
  },
  created () {
    //this.getLinks()
    //this.getLinksCount()
    emitter.all.delete('check-image-item')
    emitter.all.delete('delete-image-item')
    emitter.all.delete('upload-image-item')
    emitter.on('check-image-item', (event) => this.checkImageItem(event.id, event.isSave))
    emitter.on('delete-image-item', (event) => this.deleteImageItem(event.mode))
    emitter.on('upload-image-item', () => this.uploadImageItem())

  },
  computed: {
    isSelected () {
      return this.newImages.length > 0
    },
    getImagesCount () {
      return this.images.length
    }
  },
  methods: {
    clickPanel () {
      if (!this.active) {
        this.active = true
      } else {
        this.active = false
      }
    },
    checkImageItem (id, isSave) {
      let images = {}
      if (isSave) {
        images = this.images
      } else {
        images = this.newImages
      }

      const index = images.findIndex((item) => item.id === id)

      if (index >= 0 ) {
        images[index].checked = !images[index].checked
      }
    },
    deleteImageItem (isSavedMode) {
      let images = {}
      if (isSavedMode) {
        images = this.images
      } else {
        images = this.newImages
      }

      for( let i = 0; i < images.length; i++){ 
        if ( images[i].checked) { 
            images.splice(i, 1); 
            i--; 
        }
      }

      let index = 0
      images.forEach((item) => {
        item.id = index;
        index = index + 1;
      });

    },
    uploadImageItem () {
      for( let i = 0; i < this.newImages.length; i++){ 
        this.upload(this.newImages[i])
      }
    },
    async upload (image) {
      
      let formData = new FormData();
      
      formData.append('file', image.file.slice(), image.file.name);
      formData.append('elId', this.elId);
      //console.log('formData: ', formData.get('elId'))

      await axios_http
      .post('/img-upload', 
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            image.url = 'Uploading ... ' + Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ).toString() + '%';
            //this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            //if (this.uploadPercentage === 100){
            //  this.uploading = false
            //}
          }.bind(this)
        })
      .then((response) => {
        image.url = response.data
        image.uploaded = true

        //this.forceRerender();
      })
      .catch((error) => {
        console.log('Image Upload unsuccessful :(', error)
      })
      
    },
    doSelect () {
      this.getFiles()
    },
    doUpload () {
      emitter.emit('upload-image-item')
      this.isUploaded = true
    },
    doApplay () {
      //push newImages to images
      //this.newImages.forEach((item) => {
      //  item.url = this.baseUrl + this.pathFolder + this.pathFile + this.image.file.name
      //})
      //console.log('this.newImages: ', this.newImages)
      emitter.emit('applay-image-item', {
        newImages: this.newImages  
      })
      this.newImages = [];
      
      this.uploadOpen = false
      this.isUploaded = false
    },
    addImageLink () {
       emitter.emit('add-image-item', {
        newImage: this.image  
      })
      this.image.url = ''
      this.image.name = ''
      this.image.author = ''
    },
    doCancel () {
      this.newImages = [];
      this.uploadOpen = false
      this.isUploaded = false
    },
    async getFiles () {
      
      const dirHandle = await window.showDirectoryPicker()
//      console.log(':', dirHandle)
      this.pickerOpts.startIn = dirHandle
      const fileHandles = await window.showOpenFilePicker(this.pickerOpts)
      //this.pickerOpts.startIn = dirHandle;
      
      if (!fileHandles) {
        // User cancelled, or otherwise failed to open a file.
        return;
      }

      const allFileData = await Promise.all(
        fileHandles.map( async (fileHandle) => {
          const fileData = await fileHandle.getFile()
          return fileData
        })
      )

      // get file contents
      //const fileData = await fileHandles[0].getFile();
      //console.log('fileData: ', fileData);

      // Check if handle exists inside directory our directory handle
      const relativePaths = await dirHandle.resolve(fileHandles[0]);
      console.log('relativePaths:', relativePaths)

      if (relativePaths === null) {
        // Not inside directory handle
      } else {
        // relativePaths is an array of names, giving the relative path
        let path = '';
        for (const name of relativePaths) {
          path = path + '\\' + name;
          // log each entry
        }
        
        const pstn = path.lastIndexOf('\\')
        this.pathFile = path.substring(0, pstn+1)
        
        //console.log('path: ', path)
        let index = 0
        
        this.newImages = allFileData.map((item) => {
          let image = {};
          image.isSave = false
          image.isNew = true
          image.file = item
          image.url = ''
          image.name = ''
          image.author = ''
          image.uploaded = false
          image.checked = false;
          image.id = index
          index = index+1
          //console.log('create index id: ', index)
          return image
        })
        //console.log(this.newImages); 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/link-upload-group.scss';
  .modal {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .9);
    overflow-y: scroll;

  }
  .groupControl {
    margin-top: 16px;
  }
  .groupControl button{
    margin-left: 24px;
  }
</style>
