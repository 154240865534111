<template>
  <div class="file-pnl-item">
    <span v-html="getLink"/>
    <span style="font-size:12px"> ({{ link.url }})</span>
  </div>
</template>

<script>

export default {
  name: 'LinksItem',
  props: ['link'],
  computed: {
    getLink () {
      return '<a href="' + this.link.url + '" target="_blank">' + this.link.name + '</a>'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/files-item.scss'
</style>
