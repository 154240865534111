<template>
  <div class="image-area" contenteditable="true" @paste="pasteImage"> Paste an image ...</div>
  <Teleport to="body">
    <div class="modal" v-show="open">
      <div class="modalDialog">
        <div class="modalContent">
          <div class="modalHeader">
          </div>
          <div class="modalBody">
            <img id="container"/>
            <div class="imageControl">
              <div v-if="!uploaded" class="uploadControl" >
                <button @click="upload">Upload</button>
              </div>
              <div v-else class="uploadControl" >
                <div v-if="uploading">
                  <progress max="100" :value.prop="uploadPercentage"></progress>
                </div>
                <div v-else>
                  <div class="inputControl">
                    <input type="text" v-model="textSrc">
                    <button @click="copySrc">Copy</button>
                  </div>
                  <div class="inputControl">
                    <input type="text" v-model="textHref">
                    <button @click="copyHref">Copy</button>
                  </div>
                  <div class="inputControl">
                    <input type="text" v-model="textPath">
                    <button @click="copyPath">Copy</button>
                  </div>
                </div>
              </div>
              <div class="closeControl">
                <button @click="open = false">Close</button>
                <p class="messageControl">Url copied: {{ urlCopied }}</p>
              </div>
            </div>
          </div>
          <div class="modalFooter">
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import axios_http from '@/http/index.js'

export default {
  props: ['elId', 'prfx'],
  data() {
    return {
      open: false,
      file: null,
      textSrc: '',
      textHref: '',
      textPath: '',
      uploaded: false,
      uploading: true,
      uploadPercentage: 0, 
      urlCopied: ''
    }
  },
  methods: {
    pasteImage (pasteEvent) {
      this.testSrc = ''
      this.testHref = ''
      this.testPath = ''
      this.uploaded = false
      this.uploading = true
      this.uploadPercentage = 0 
      this.urlCopied = ''

      pasteEvent.preventDefault()

      //console.log('pasteEvent.clipboardData.items: ', pasteEvent.clipboardData.items[0])

      let item = null;
      for (let i = 0; i < pasteEvent.clipboardData.items.length; i++) {
        if (pasteEvent.clipboardData.items[i].type.indexOf("image") == -1) continue;
        item = pasteEvent.clipboardData.items[i];
      }
      
      if (item !== null) {
        if (item.type.indexOf("image") === 0) {

          this.open = true
          this.file = item.getAsFile();

          const reader = new FileReader();
          reader.onload = function(event) {
            document.getElementById("container").src = event.target.result;
          };

          reader.readAsDataURL(this.file);
        }
      }
    },
    async upload () {
      this.uploaded = true
      
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('elId', this.prfx + this.elId);
      //console.log('formData: ', formData.get('elId'))

      await axios_http
      .post('/img-upload', 
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            if (this.uploadPercentage === 100){
              this.uploading = false
            }
          }.bind(this)
        })
      .then((response) => {
        this.textPath = response.data
        this.textSrc = '<img src="' + response.data + '" alt="" width="100%">' 
        this.textHref = '<a href="' + response.data + '" target="_blank">' + response.data + '</a>' 
        //console.log(response.data)
      })
      .catch((error) => {
        console.log('Image Upload unsuccessful :(', error)
      })
      
    },
    copySrc () {
      navigator.clipboard.writeText(this.textSrc);
      this.urlCopied = this.textSrc
    },
    copyHref () {
      navigator.clipboard.writeText(this.textHref);
      this.urlCopied = this.textHref
    },
    copyPath () {
      navigator.clipboard.writeText(this.textPath);
      this.urlCopied = this.textPath
    }
  }
}

</script>

<style lang="scss" scoped>
.image-area {
  color: var(--light-color);
  padding-bottom: 0.25rem;
  background-color: var(--main-4-color);
}
.imageControl {
  margin-top: 8px;
  display: -webkit-flex; 
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}
.inputControl {
  margin-bottom: 8px;
}
.inputControl input{
  width: 280px;
}
.messageControl {
  color: white;
}

.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
}
.modalDialog{
  position: relative;
  z-index: 101;
  margin: auto;
  padding: 0;
  width: inherit;
  height: inherit;
}
.modalContent{
  position: relative;
  z-index: 102;
  overflow: auto;
  width: inherit;
  height: inherit;
}
.modalHeader{
  padding: 10px;
  width: calc(100vw - 30px);
  position: absolute;
  z-index: 104;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.modalBody{
  position: relative;
  z-index: 103;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 90%;
}

.modalBody img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.modalFooter{
  padding: 10px;
  width: calc(100vw - 30px);
  position: absolute;
  z-index: 104;
  bottom: 0;
  left: 0;
  /* display: flex;
  justify-content: space-between;*/
}
</style>