import { createStore } from 'vuex'
import auth from './modules/auth/index.js'
/*import login from './modules/login/index.js'*/
import themes from './modules/themes/index.js'
import values from './modules/values/index.js'
import classes from './modules/classes/index.js'

import classes2 from './modules/classes/classes.js'
import themes2 from './modules/themes/themes.js'

import data from './modules/data/index.js'
import eventtypes from './modules/eventtypes/index.js'
import eventdata from './modules/eventdata/index.js'
import channel from './modules/channeldata/index.js'
//import medication from './modules/medicationdata/index.js'

//import hibi from './modules/hibi/index.js'
//import event from './modules/event/index.js'
//import myhibis from './modules/myhibis/index.js'
//import events from './modules/events/index.js'

export default createStore({
  modules: {
    auth,
/*    login,*/
    themes,
    values,
    classes,

    classes2,
    themes2,
    
    data,
    eventtypes,
    eventdata,
    channel,
//    medication,

//    hibi,
//    event,
//    myhibis,
//    events
  }
})
