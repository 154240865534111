// import axios from 'axios'
import axios_http from '@/http/index.js'

const state = {
  eventdataItems: [],
  eventdataValue: null,
  upeventdataItem: [],
  upeventdataValue: null
}

const mutations = {
  UPDATE_EVENTDATA_ITEMS (state, payload) {
    if (payload != '') {
      state.eventdataItems = payload
    } else {
      state.eventdataItems = []
    }
  },
  UPDATE_EVENTDATA_VALUE (state, payload) {
    state.eventdataValue = payload
  },
  UPDATE_UPEVENTDATA_ITEM (state, payload) {
    if (payload != '') {
      state.upeventdataItem = payload
    } else {
      state.upeventdataItem = []
    }
  },
  UPDATE_UPEVENTDATA_VALUE (state, payload) {
    state.upeventdataValue = payload
  }
}

const actions = {

  async filterEventdataItems ({ commit }, payload) {
    await axios_http
      .post('/eventdata/filter', payload.filter)
      .then((response) => {
        //console.log('GET /eventdata/filter call successful: ', response.data)
        commit('UPDATE_EVENTDATA_ITEMS', response.data)
        commit('UPDATE_EVENTDATA_VALUE', payload.value)
      })
  },
  async getEventdataItem ({ commit }, payload) {
    // console.log('api/events')
    await axios_http
      //.get('/myhibi/' + selectedValue.toString())
      .post('/eventdata/filter', payload.filter)
      .then((response) => {
        //console.log('GET call /eventdata/filter successful: ', payload.value, response, )
        commit('UPDATE_UPEVENTDATA_ITEM', response.data)
        commit('UPDATE_UPEVENTDATA_VALUE', payload.value)
      })
      .catch((error) => {
        console.log('GET event node data call unsuccessful :(', error)
      })
  },
  async uploadEventdataItem ({ commit }, payload) {
    //console.log('dataItem:', dataItem)
    await axios_http
      .post('/eventdata/upload', payload)
      .then((response) => {
        // console.log('UPDATE_MEDCOND_ITEM: ', response.data)
        //console.log('GET call /eventdata/upload successful: ', payload.value, response, )
        commit('UPDATE_UPEVENTDATA_ITEM', response.data)
        //commit('UPDATE_UPEVENTDATA_VALUE', payload.value)
        //console.log('response: ', response.data, MedcondItem)
      })
      .catch((error) => {
        console.log('POST upload myhibi data call unsuccessful :(', error)
      })
  }
}

const getters = {
  eventdataItems: state => state.eventdataItems,
  eventdataValue: state => state.eventdataValue,
  upeventdataItem: state => state.upeventdataItem,
  upeventdataValue: state => state.upeventdataValue
}

const eventdataModule = {
  state,
  mutations,
  actions,
  getters
}

export default eventdataModule
