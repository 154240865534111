<!--
/**
 * @fileoverview Calendar component
 * @license MIT
 * @author Rafal Pospiech <neuronet.io@gmail.com>
 * @package GanttElastic
 */
-->
<template>
  <div
    class="gantt-elastic__calendar-wrapper"
    :style="{ ...style['calendar-wrapper'], width: state.options.width + 'px' }"
  >
    <div class="gantt-elastic__calendar" :style="{ ...style['calendar'], width: state.options.width + 'px' }">
      <CalendarRow :items="dates.months" which="month" v-if="state.options.calendar.month.display"></CalendarRow>
      <CalendarRow :items="dates.days" which="day" v-if="state.options.calendar.day.display"></CalendarRow>
      <CalendarRow :items="dates.hours" which="hour" v-if="state.options.calendar.hour.display"></CalendarRow>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import dayjs from 'dayjs';
import CalendarRow from './calendarRow.vue';

export default {
  name: 'Calendar',
  components: {
    CalendarRow
  },
  data() {
    return {};
  },
  setup () {
    const state = inject('state')
    const style = inject('style')
    const root = inject('root')
    return { root, state, style }
  },

  methods: {
    /**
     * How many hours will fit?
     *
     * @returns {object}
     */
    howManyHoursFit(dayIndex) {
      const stroke = 1;
      const additionalSpace = stroke + 2;
      let fullCellWidth = this.state.options.times.steps[dayIndex].width.px;
      let formatNames = Object.keys(this.state.options.calendar.hour.format);
      for (let hours = 24; hours > 1; hours = Math.ceil(hours / 2)) {
        for (let formatName of formatNames) {
          if (
            (this.state.options.calendar.hour.maxWidths[formatName] + additionalSpace) * hours <= fullCellWidth &&
            hours > 1
          ) {
            return {
              count: hours,
              type: formatName
            };
          }
        }
      }
      return {
        count: 0,
        type: ''
      };
    },

    /**
     * How many days will fit?
     *
     * @returns {object}
     */
    howManyDaysFit() {
      const stroke = 1;
      const additionalSpace = stroke + 2;
      let fullWidth = this.state.options.width;
      let formatNames = Object.keys(this.state.options.calendar.day.format);
      for (let days = this.state.options.times.steps.length; days > 1; days = Math.ceil(days / 2)) {
        for (let formatName of formatNames) {
          if (
            (this.state.options.calendar.day.maxWidths[formatName] + additionalSpace) * days <= fullWidth &&
            days > 1
          ) {
            return {
              count: days,
              type: formatName
            };
          }
        }
      }
      return {
        count: 0,
        type: ''
      };
    },

    /**
     * How many months will fit?
     *
     * @returns {object}
     */
    howManyMonthsFit() {
      const stroke = 1;
      const additionalSpace = stroke + 2;
      let fullWidth = this.state.options.width;
      let formatNames = Object.keys(this.state.options.calendar.month.format);
      //let currentMonth = dayjs(this.state.options.times.firstTime);
      //let previousMonth = currentMonth.clone();
      //const lastTime = this.state.options.times.lastTime;
      let monthsCount = this.root.monthsCount(
        this.state.options.times.firstTime,
        this.state.options.times.lastTime
      );
      if (monthsCount === 1) {
        for (let formatName of formatNames) {
          if (this.state.options.calendar.month.maxWidths[formatName] + additionalSpace <= fullWidth) {
            return {
              count: 1,
              type: formatName
            };
          }
        }
      }
      for (let months = monthsCount; months > 1; months = Math.ceil(months / 2)) {
        for (let formatName of formatNames) {
          if (
            (this.state.options.calendar.month.maxWidths[formatName] + additionalSpace) * months <= fullWidth &&
            months > 1
          ) {
            return {
              count: months,
              type: formatName
            };
          }
        }
      }
      return {
        count: 0,
        type: formatNames[0]
      };
    },

    /**
     * Generate hours
     *
     * @returns {array}
     */
    generateHours() {
      let allHours = [];
      if (!this.state.options.calendar.hour.display) {
        return allHours;
      }
      const steps = this.state.options.times.steps;
      //const localeName = this.state.options.locale.name;
      for (let hourIndex = 0, len = steps.length; hourIndex < len; hourIndex++) {
        const hoursCount = this.howManyHoursFit(hourIndex);
        if (hoursCount.count === 0) {
          continue;
        }
        const hours = { key: hourIndex + 'step', children: [] };
        const hourStep = 24 / hoursCount.count;
        const hourWidthPx = steps[hourIndex].width.px / hoursCount.count;
        for (let i = 0, len = hoursCount.count; i < len; i++) {
          const hour = i * hourStep;
          let index = hourIndex;
          if (hourIndex > 0) {
            index = hourIndex - Math.floor(hourIndex / 24) * 24;
          }
          let textWidth = 0;
          if (typeof this.state.options.calendar.hour.widths[index] !== 'undefined') {
            textWidth = this.state.options.calendar.hour.widths[index][hoursCount.type];
          }
          let x = steps[hourIndex].offset.px + hourWidthPx * i;
          hours.children.push({
            index: hourIndex,
            key: 'h' + i,
            x,
            y: this.state.options.calendar.day.height + this.state.options.calendar.month.height,
            width: hourWidthPx,
            textWidth,
            height: this.state.options.calendar.hour.height,
            label: this.state.options.calendar.hour.formatted[hoursCount.type][hour]
          });
        }
        allHours.push(hours);
      }
      return allHours;
    },

    /**
     * Generate days
     *
     * @returns {array}
     */
    generateDays() {
      let days = [];
      if (!this.state.options.calendar.day.display) {
        return days;
      }
      const daysCount = this.howManyDaysFit();
      if (daysCount.count === 0) {
        return days;
      }
      const steps = this.state.options.times.steps;
      const localeName = this.state.options.locale.name;
      const dayStep = Math.ceil(steps.length / daysCount.count);
      for (let dayIndex = 0, len = steps.length; dayIndex < len; dayIndex += dayStep) {
        let dayWidthPx = 0;
        // day could be shorter (daylight saving time) so join widths and divide
        for (let currentStep = 0; currentStep < dayStep; currentStep++) {
          if (typeof steps[dayIndex + currentStep] !== 'undefined') {
            dayWidthPx += steps[dayIndex + currentStep].width.px;
          }
        }
        const date = dayjs(steps[dayIndex].time);
        let textWidth = 0;
        if (typeof this.state.options.calendar.day.widths[dayIndex] !== 'undefined') {
          textWidth = this.state.options.calendar.day.widths[dayIndex][daysCount.type];
        }
        let x = steps[dayIndex].offset.px;
        days.push({
          index: dayIndex,
          key: steps[dayIndex].time + 'd',
          x,
          y: this.state.options.calendar.month.height,
          width: dayWidthPx,
          textWidth,
          height: this.state.options.calendar.day.height,
          label: this.state.options.calendar.day.format[daysCount.type](date.locale(localeName))
        });
      }
      return days.map(item => ({
        key: item.key,
        children: [item]
      }));
    },

    /**
     * Generate months
     *
     * @returns {array}
     */
    generateMonths() {
      let months = [];
      if (!this.state.options.calendar.month.display) {
        return months;
      }
      const monthsCount = this.howManyMonthsFit();
      if (monthsCount.count === 0) {
        return months;
      }
      //const steps = this.root.state.options.times.steps;
      const localeName = this.state.options.locale.name;
      let formatNames = Object.keys(this.state.options.calendar.month.format);
      let currentDate = dayjs(this.state.options.times.firstTime);
      for (let monthIndex = 0; monthIndex < monthsCount.count; monthIndex++) {
        let monthWidth = 0;
        let monthOffset = Number.MAX_SAFE_INTEGER;
        let finalDate = dayjs(currentDate)
          .add(1, 'month')
          .startOf('month');
        if (finalDate.valueOf() > this.state.options.times.lastTime) {
          finalDate = dayjs(this.state.options.times.lastTime);
        }
        // we must find first and last step to get the offsets / widths
        for (let step = 0, len = this.state.options.times.steps.length; step < len; step++) {
          let currentStep = this.state.options.times.steps[step];
          if (currentStep.time >= currentDate.valueOf() && currentStep.time < finalDate.valueOf()) {
            monthWidth += currentStep.width.px;
            if (currentStep.offset.px < monthOffset) {
              monthOffset = currentStep.offset.px;
            }
          }
        }
        let label = '';
        let choosenFormatName;
        for (let formatName of formatNames) {
          if (this.state.options.calendar.month.maxWidths[formatName] + 2 <= monthWidth) {
            label = this.state.options.calendar.month.format[formatName](currentDate.locale(localeName));
            choosenFormatName = formatName;
          }
        }
        let textWidth = 0;
        if (typeof this.state.options.calendar.month.widths[monthIndex] !== 'undefined') {
          textWidth = this.state.options.calendar.month.widths[monthIndex][choosenFormatName];
        }
        let x = monthOffset;
        months.push({
          index: monthIndex,
          key: monthIndex + 'm',
          x,
          y: 0,
          width: monthWidth,
          textWidth,
          choosenFormatName,
          height: this.state.options.calendar.month.height,
          label
        });
        currentDate = currentDate.add(1, 'month').startOf('month');
        if (currentDate.valueOf() > this.state.options.times.lastTime) {
          currentDate = dayjs(this.state.options.times.lastTime);
        }
      }
      return months.map(item => ({
        key: item.key,
        children: [item]
      }));
    },

    /**
     * Sum all calendar rows height and return result
     *
     * @returns {int}
     */
    calculateCalendarDimensions({ hours, days, months }) {
      let height = 0;
      if (this.state.options.calendar.hour.display && hours.length > 0) {
        height += this.state.options.calendar.hour.height;
      }
      if (this.state.options.calendar.day.display && days.length > 0) {
        height += this.state.options.calendar.day.height;
      }
      if (this.state.options.calendar.month.display && months.length > 0) {
        height += this.state.options.calendar.month.height;
      }
      this.state.options.calendar.height = height;
    }
  },

  computed: {
    dates() {
      const hours = this.generateHours();
      const days = this.generateDays();
      const months = this.generateMonths();
      const allDates = { hours, days, months };
      this.calculateCalendarDimensions(allDates);
      return allDates;
    }
  }
};
</script>
