<!--
/**
 * @fileoverview Chart component
 * @license MIT
 * @author Rafal Pospiech <neuronet.io@gmail.com>
 * @package GanttElastic
 */
-->
<template>
  <div class="gantt-elastic__chart" :style="{ ...style['chart'] }" ref="chart">
    <div
      class="gantt-elastic__chart-calendar-container"
      ref="chartCalendarContainer"
      :style="{
        ...style['chart-calendar-container'],
        height: stateOptionsCalendarHeight + 'px',
        'margin-bottom': stateOptionsCalendarGap + 'px'
      }"
    >
      <Calendar></Calendar>
    </div>
    <div
      class="gantt-elastic__chart-graph-container"
      ref="chartGraphContainer"
      :style="{
        ...style['chart-graph-container'],
        height: stateOptionsHeight - stateOptionsCalendarHeight + 'px'
      }"
    >
      <div
        :style="{
          ...style['chart-area'],
          width: stateOptionsWidth + 'px',
          height: stateOptionsRowsHeight + 'px'
        }"
      >
        <div
          class="gantt-elastic__chart-graph"
          ref="chartGraph"
          :style="{ ...style['chart-graph'], height: '100%' }"
        >
          <svg
            class="gantt-elastic__chart-graph-svg"
            :style="{ ...style['chart-graph-svg'] }"
            ref="chartGraphSvg"
            x="0"
            y="0"
            :width="stateOptionsWidth + 'px'"
            :height="stateOptionsAllVisibleTasksHeight + 'px'"
            xmlns="http://www.w3.org/2000/svg"
          >
            <DaysHighlight></DaysHighlight>
            <Grid></Grid>

            <DependencyLines :tasks="root.visibleTasks"></DependencyLines>
            
            <g
              class="gantt-elastic__chart-row-wrapper"
              :style="{ ...style['chart-row-wrapper'] }"
              v-for="task in root.visibleTasks"
              :task="task"
              :key="task.id"
            >
              <component :task="task" :is="task.type"></component>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import Calendar from '../Calendar/calendar.vue';
import DaysHighlight from './daysHighlight.vue';
import Grid from './grid.vue';
import DependencyLines from './dependencyLines.vue';
import Task from './Row/task.vue';
import Milestone from './Row/milestone.vue';
import Project from './Row/project.vue';
export default {
  name: 'Chart',
  components: {
    Calendar,
    DaysHighlight,
    Grid,
    DependencyLines,
    Task,
    Milestone,
    Project
  },
  data() {
    return {
      moving: false
    };
  },
  setup () {
    const state = inject('state')
    const style = inject('style')
    const root = inject('root')
    return { root, state, style }
  },

  /**
   * Mounted
   */
  mounted() {
    this.state.refs.chart = this.$refs.chart;
    this.state.refs.chartCalendarContainer = this.$refs.chartCalendarContainer;
    this.state.refs.chartGraphContainer = this.$refs.chartGraphContainer;
    this.state.refs.chartGraph = this.$refs.chartGraph;
    this.state.refs.chartGraphSvg = this.$refs.chartGraphSvg;
  },

  computed: {
    stateOptionsAllVisibleTasksHeight () {//
      return this.state.options.allVisibleTasksHeight
    },
    stateOptionsCalendarGap () {
      return this.state.options.calendar.gap
    },
    stateOptionsCalendarHeight () {
      return this.state.options.calendar.height
    },
    stateOptionsHeight () { //
      return this.state.options.height
    },
    stateOptionsRowsHeight () { //
      return this.state.options.rowsHeight
    },
    stateOptionsWidth () { //
      return this.state.options.width
    },

    /**
     * Get view box
     *
     * @returns {string}
     */
    //getViewBox() {
    //  return ``0 0 ${this.state.options.width} ${this.state.options.allVisibleTasksHeight}`;
    //}
  }
};
</script>
