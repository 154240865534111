<template>
  <div v-if="isFile" class="file-pnl-item">
    {{ file.name }}&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:12px">({{ file.url }})</span>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <button class="hb-button" @click="openFile"> Open file </button>
  </div>
  <div v-if="!isFile" class="file-pnl-item">
    <span v-html="getLink"/>
    <span style="font-size:12px"> ({{ file.url }})</span>
  </div>
</template>

<script>

export default {
  name: 'FileItem',
  props: ['file'],
  computed: {
    isFile () {
      return this.file.url[1] == ':'
    },
    getLink () {
      return '<a href="' + this.file.url + '" target="_blank">' + this.file.name + '</a>'
    }
  },
  methods: {
    openFile () {
      try {
        //console.log('item: ', this.item)
        this.$store.dispatch('openFile', this.file.url)
      } catch (error) {
        console.log('Open file error:', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/files-item.scss'
</style>
