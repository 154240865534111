<template>
  <node-view-wrapper class="instagram-block">
    <span class="label">Instagram</span>

    <div class="html-content" v-html="node.attrs.beginTag + node.attrs.hibiHtml + node.attrs.endTag" />

<!--
    <div class="html-content">
      {{ node.attrs.beginTag }} {{ node.attrs.hibiHtml }} {{ node.attrs.endTag }}
    </div>
-->

  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  //methods: {
  //  increase() {
  //    this.updateAttributes({
  //      count: this.node.attrs.count + 1,
  //    })
  //  },
  //},
}
</script>

<style lang="scss" scoped>
.instagram-block {
  background: #833AB4;
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
  height: 100%;
}

.label {
  margin-left: 1rem;
  background-color: #0D0D0D;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.html-content {
  margin-top: 1.5rem;
  padding: 1rem;
  height: 100%;
}
</style>