import axios from 'axios';

const axios_http = axios.create({
    //Local
    //baseURL: process.env.VUE_APP_SERVER_URL, 

    //aiviron.com
    baseURL: 'https://db.aiviron.com/', 

    withCredentials: true,
//    headers: {
//      'Access-Control-Allow-Origin': '*'
//    }
  })

export default axios_http;