import { Extension } from "@tiptap/core"
import "@tiptap/extension-text-style"

const LineHeight = Extension.create({
	name: "lineHeight",
	
	addOptions() {
		return {
			types: ["heading", "paragraph"],
			defaultHeight: 1.5
		}
	},
	
	addGlobalAttributes() {
		return [{
			types: this.options.types,
			attributes: {
				lineHeight: {
					default: this.options.defaultHeight,
					parseHTML: element => 
						element.style.lineHeight || this.options.defaultHeight,
					renderHTML: attributes => {
						if (!attributes.lineHeight) {
							return {};
						}

						return {
							style: `line-height: ${attributes.lineHeight}`,
						}
					}
				}
			},
		}]
	},

	addCommands() {
		return {
			setLineHeight:
				(height) =>
				({ commands }) => {
					//if (!this.options.height) {
					//	return false;
					//}
					
					//console.log('height: ', height)
					
					return this.options.types.every((type) =>
						commands.updateAttributes(type, { lineHeight: height })
					);
				},

			unsetLineHeight: 
				() =>
				({ commands }) => {
					return this.options.types.every((type) =>
						commands.resetAttributes(type, "lineHeight")
					);
				},
		}
	},
})

export default LineHeight;