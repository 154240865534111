import axios_http from '@/http/index.js'

const state = {
  interfaces: 0,
  isIAMaster: false,
  token: null,
  loading: false,
  fileopen: null
}

const mutations = {
  SET_INTERFACES (state, interfaces) {
    state.interfaces = interfaces & 42
  },
  SET_TOKEN (state, token) {
    state.token = token
  },
  LOGIN_PENDING (state) {
    state.loading = true
  },
  LOGOUT_PENDING (state) {
    state.loading = true
  },
  SIGNUP_PENDING (state) {
    state.loading = true
  },
  LOGIN_SUCCESS (state) {
    state.loading = false
  },
  LOGOUT_SUCCESS (state) {
    state.loading = false
  },
  SIGNUP_SUCCESS (state) {
    state.loading = false
  },
  OPEN_FILE (state, payload) {
    state.fileopen = payload
  }

}

const actions = {
  async set_interfaces ({ commit }) {
    await axios_http.post('/main')
      .then((response) => {
        // console.log('token', response.data.token, response)
        //console.log('set_interfaces data: ', response.data)
        if (response.data.atd.ivl) {
          commit('SET_INTERFACES', response.data.atd.ivl)
        } else {
          commit('SET_INTERFACES', 0)
        }

        localStorage.setItem('token', response.data.token)
        commit('SET_TOKEN', response.data.token)
      })
      .catch((error) => {
        console.log('/api/main unsuccessful :(', error)
        throw error
      })
  },
  async auth_login ({ commit }, payload) {
    commit('LOGIN_PENDING')
    // console.log('login payload', payload)
    await axios_http.post('/login', { login: payload })
    .then((response) => {

      localStorage.setItem('token', response.data.token)
      commit('SET_TOKEN', response.data.token)
      commit('LOGIN_SUCCESS')
    })
      .catch((error) => {
        console.log('/api/login unsuccessful :(', error)
        commit('LOGIN_SUCCESS')
        throw error
      })
  },
  async auth_signup ({ commit }, payload) {
    commit('SIGNUP_PENDING')
    // console.log('login payload', payload)
    await axios_http
      .post('/signup', { signup: payload })
      .then((response) => {
        // console.log('token', response.data.token, response)
        localStorage.setItem('token', response.data.token)
        commit('SET_TOKEN', response.data.token)
        commit('SIGNUP_SUCCESS')
      })
      .catch((error) => {
          console.log('/api/signup unsuccessful :(', error)
          commit('SIGNUP_SUCCESS')
          throw error
      })
  },
  async auth_logout ({ commit }) {
    commit('LOGOUT_PENDING')
    await axios_http
      .post('/logout')
      .then((response) => {
        //console.log('auth_logout: ', response.data.token)
        localStorage.setItem('token', response.data.token)
        commit('SET_TOKEN', null)
        commit('LOGOUT_SUCCESS')
      })
      .catch((error) => {
          console.log('/api/logout unsuccessful :(', error)
          localStorage.setItem('token', null)
          commit('SET_TOKEN', null)
          commit('LOGOUT_SUCCESS')
          throw error
      })
  },
  async openFile ({ commit }, file) {
    await axios_http
      .post('/file/open', {"file": file})
      .then((response) => {
        // console.log('respone: ', response)
        commit('OPEN_FILE', response.data)
      })
      .catch((error) => {
        console.log('Open file error :(', error)
      })
  }
}

const getters = {
  interfaces: state => state.interfaces,
  isIPMaster: state => state.isIPMaster,
  token: state => state.token,
  loading: state => state.loading,
}

const authModule = {
  state,
  mutations,
  actions,
  getters
}

export default authModule
