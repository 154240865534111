<template>
<!--      
      <div>
      <button @click="resetImg()">Reset</button>

      </div>
-->
  <Swiper 
    @swiper="onSwiper"
    :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }"
    :autoplay="{delay: 5000}"
    :space-between="10"
    :navigation="true"
    :pagination="{ clickable: true, type: 'fraction'}"
    lazy
    :thumbs="{ swiper: thumbsSwiper}"
    class="mySwiper2"
    >
    <SwiperSlide v-for="(item, index) in images" 
      :key="index" class="swiper-lazy">
      <img :src="item.url" />
<!--      
      <img :src="item.url" class="swiper-lazy" @click="enlargeImg()" id="img1"/>     
-->      
      <div class="swiper-lazy-preloader"></div>
    </SwiperSlide>

<!--    
    <SwiperSlide v-for="n in 128" :key="n">
      <img :src="'https://picsum.photos/1024/600?random='+n" class="swiper-lazy"/>
      <div class="swiper-lazy-preloader"></div>
    </SwiperSlide>
-->
  </Swiper>
  

  <Swiper 
    @swiper="setThumbsSwiper"
    :space-between="10"
    :slides-per-view="10" 
    navigation
    grab-cursor
    :preload-images="false"
    :watchSlidesProgress="true"
    class="mySwiper"
    >
    <SwiperSlide v-for="(item, index) in images" 
      :key="index" >
      <img :src="item.url" />
    </SwiperSlide>

<!--
    <SwiperSlide v-for="n in 128" :key="n" >
      <img :src="'https://picsum.photos/1024/600?random='+n" />
    </SwiperSlide>
-->

  </Swiper>

</template>

<script>
//    :autoplay="{delay: 10000}"

// import Swiper core and required components
  import SwiperCore, { Navigation, Pagination, A11y, Lazy, Thumbs, Autoplay } from 'swiper'

  import {Swiper, SwiperSlide } from 'swiper/vue';

  import 'swiper/css';

  import "swiper/css/free-mode"
  import "swiper/css/navigation"
  import "swiper/css/pagination"
  import "swiper/css/a11y"
  import "swiper/css/lazy"
  import "swiper/css/thumbs"
  import "swiper/css/autoplay"

  //import 'swiper/swiper-bundle.css'
  //import 'swiper'

  SwiperCore.use([Navigation, Pagination, A11y, Lazy, Thumbs, Autoplay]);
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    props: ['images', 'currentSlide'],
    data() {
      return {
        thumbsSwiper: null,
        swiperRef: null
      }
    },
    beforeUpdate () {
      if (!this.swiperRef.autoplay.running) {
              this.swiperRef.slideTo(this.currentSlide)
      }
      //console.log('beforeUpdate: ', this.swiperRef.autoplay.running, this.currentSlide)
    },
    methods: {
      setThumbsSwiper(swiper) {
        this.thumbsSwiper = swiper
      },
      onSwiper(swiper) {
        this.swiperRef = swiper
      },
      // Function to set image dimensions
      enlargeImg () {
        const img = document.getElementById("img1");
        //img.style.width = "60%";
        //img.style.height = "auto";
        //img.style.transition = "width 0.5s ease";

        img.style.transform = "scale(1.5)";
        img.style.transition = "transform 0.25s ease";
      },
      // Function to reset image dimensions
      resetImg () {
        const img = document.getElementById("img1");
        console.log(img)
        //img.style.width = "100%";
        //img.style.height = "auto";

        img.style.width = "auto";
        img.style.height = "50%";

        img.style.transition = "width 0.5s ease";
      }
    }
  }
</script>


<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  max-height:  950px;
  object-fit: cover;
}

.mySwiper2 wimg {
  display: block;
  width: auto;
  height: 100%;
  max-height:  950px;
  object-fit: cover;
  background-color: black;
}

.swiper-thumbs wimg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
/*  
  height: 100vh;  100%  
  width: 100vw;   100% 
*/ 
 
  width: 80%;
  height: 80%;
  
/*
  object-fit: contain;
  height: 100%;    
  width: 100%;    
  object-fit: scale-down;
*/
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 20%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

</style>

