import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import TwitterBlockComponent from './TwitterBlockComponent.vue'

export default Node.create({
  name: 'TwitterBlockComponent',

  group: 'block',
  atom: true,

  addOptions: {
		HTMLAttributes: {
			class: "twitter-tweet"
		}
	},

	addAttributes() {
		return {
			hibiHtml: {
				default: ``,
				parseHTML: element => element.innerHTML,
			},
      beginTag: {
        default: '<hibi-twitter-tweet>'
      },
      endTag: {
        default: '</hibi-twitter-tweet>'
      }
		}
	},
	parseHTML () {
		return [
			{
				tag: 'hibi-twitter-tweet',
				//tag: 'div[class="twitter-tweet twitter-tweet-rendered"]',
			},
			{priority: 100},
			//{consuming: true},
		]
	},
	renderHTML({HTMLAttributes}) {
		const el = document.createElement('hibi-twitter-tweet');
		//el.classList.add('twitter-tweet');
		//const el = document.createElement('div');
		//el.classList.add('twitter-tweet');
		//el.classList.add('twitter-tweet-rendered');
		
		el.innerHTML = HTMLAttributes['hibiHtml'];
		//console.log("HTMLAttributes['value']", HTMLAttributes['hibiHtml'], el)

		return el
	},

  addNodeView() {
    return VueNodeViewRenderer(TwitterBlockComponent)
  },
})