<template>
  <div class="links-container">

    <div class="ln-container-Controls">
      <div class="ln-item-Control">
        <div class="ln-input-Control">
          <button :disabled="!isUrlEntered" @click="addLinkLink()">Add</button>
          <input type="text" placeholder="Enter name ..." v-model="textName">
          <input type="text" placeholder="Enter url ..." v-model="textHref">
        </div>
      </div>
    </div>

    <hr>

    <LinksUploadCell :links="links" :pathLink="emptyPath" :isSavedMode="true" :isUploaded="isUploaded"/>

  </div>
</template>

<script>
import emitter from '@/emitter.js'
import LinksUploadCell from './links-upload-cell.vue'

export default {
  name: 'LinksCellGroup',
  data () {
    return {
      textHref: '',
      textName: '',
      linkCopied: '',
      isUploaded: false,
      isUploding: false,
      newLinks: [],
      link: null,
      pathLink: '',
      emptyPath: ''
    }
  },
  props: ['links', 'elId'],
  components: {
    LinksUploadCell
  },
  created () {

    emitter.all.delete('check-link-item')
    emitter.all.delete('delete-link-item')
    emitter.on('check-link-item', (event) => this.checkLinkItem(event.id, event.isSave))
    emitter.on('delete-link-item', (event) => this.deleteLinkItem(event.mode))

  },
  computed: {
    isUrlEntered () {
      return this.textHref.length > 0
    },
    getLinksCount () {
      return this.links.length
    },
    getLink () {
      return '<a href="' + this.textHref + '" target="_blank">' + this.textName + '</a>' 
    },
    getAudio () {
      return '<audio controls src="' + this.textHref + '"> </audio> <span>' + this.textName + '</span>' 
    },
    getVideo () {
      return '<video controls src="' + this.textHref + '"> </video> <span>' + this.textName + '</span>' 
    },
  },
  methods: {
    checkLinkItem (id, isSave) {
      let links = {}
      if (isSave) {
        links = this.links
      } else {
        links = this.newLinks
      }

      const index = links.findIndex((item) => item.id === id)

      if (index >= 0 ) {
        links[index].checked = !links[index].checked
      }
    },
    deleteLinkItem (isSavedMode) {
      let links = {}
      if (isSavedMode) {
        links = this.links
      } else {
        links = this.newLinks
      }

      for( let i = 0; i < links.length; i++){ 
        if ( links[i].checked) { 
            links.splice(i, 1); 
            i--; 
        }
      }

      let index = 0
      links.forEach((item) => {
        item.id = index;
        index = index + 1;
      });

    },
    addLinkLink () {
      const link = {url: this.textHref, name: this.textName}
       emitter.emit('add-link-item', {
        newLink: link
      })
      this.textHref = ''
      this.textName = ''
      this.isUploaded = false
      this.isUploding = false
      this.link = null
      this.linkCopied = ''
      navigator.clipboard.writeText(this.linkCopied);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/link-upload-group.scss';

</style>
