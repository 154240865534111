  <template>

  <div class="day-event" :style="getEventBackgroundColor">
    <div class="ev-event-top" :style="getEventTopBackgroundColor">
      <div class="ev-event-classes">
        <span class="ev-event-class">
          {{ getTypeEvent() }}
        </span>
        <span class="ev-event-class">
          - {{ getCountries() }}
        </span>
        <span class="ev-event-class">
          - {{ getClsfrs() }}
        </span>
      </div>
      <div class="ev-event-edit">
        <button class="ev-button-edit" @click="viewEvent">
          ...
        </button>
      </div>
    </div>

    <strong><span class="has-text-centered details" style="margin-bottom: 4px; font-size: 14px;"> {{ event.msrs.filter((item, index, array) => (item.vid == 10))[0].svl }}</span></strong>
    <div class="details">
      <span class="has-text-centered details">{{ getLocality() }}</span>
      <span class="has-text-centered details"><b>{{ getTimeDuration() }}</b> {{ getTimeZone() }}</span>
      <span class="has-text-centered details" v-html="event.msrs.filter((item, index, array) => (item.vid == 11))[0].svl" />
    </div>

  </div>
</template>

<script>
import moment from 'moment-timezone'
import emitter from '@/emitter.js'

export default {
  name: 'CalendarEvent',
  data () {
    return {
      newEventDetails: ''
    }
  },
  props: ['event'],
  created () {
    // console.log('calendar-event created')
    //console.log('hibicalendar-event.created : this.event', this.event)
    let time
    time = this.event.time
    time.vl = moment.tz(time.vl, time.tz).format()
    time.vle = moment.tz(time.vle, time.tz).format()

  },
  computed: {
    getEventBackgroundColor () {
      if (this.event.msrs.filter((item) => (item.vid == 13))[0].vl > 0) {
        return `background-color: ${'rgb(138,130,130)'}; color: ${'#FFFFFF'}` // rgb(153,149,166) rgb(159,136,160) rgb(97,64,61) #B9C089, #FF9999
      } else {
        return `background-color: ${'#FFFFFF'}`
      }
    },
    getEventTopBackgroundColor () {

      const id = this.event.events.nms[0].id

      let bc = ``
      switch (id) {
        case 2: //Private
          bc = `background-color: ${'rgb(153,149,166)'}`
        break;

        case 3: //Business & trade
          bc = `background-color: ${'rgb(18,44,68)'}`
        break;

        case 4: //Cultural celebration
          bc =  `background-color: ${'rgb(71,74,32)'}`
        break;

        case 5: //Art & intertainment
          bc =  `background-color: ${'rgb(194,181,164)'}`
          //bc =  `background-color: ${'rgb(147,125,103)'}`
        break;

        case 6: //Educational & scientific
          bc =  `background-color: ${'rgb(95,95,94)'}`
        break;

        case 7: //Sport
          bc =  `background-color: ${'rgb(96,124,148)'}`
        break;

        case 8: //Recreational
          bc =  `background-color: ${'rgb(41,41,41)'}`
        break;

        case 9: //Political & state
          bc =  `background-color: ${'rgb(97,64,61)'}`
        break;

        case 10:
          bc =  `background-color: ${'rgb(69,73,85)'}`
        break;

        case 11:
          bc =  `background-color: ${'rgb(18,44,68)'}`
        break;

        case 12:
          bc =  `background-color: ${'rgb(97,64,61)'}`
        break;
      }

      return bc; 
    }

  },
  methods: {
    getTypeEvent () {
      const classes = this.event.events.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getCountries () {
      const classes = this.event.countries.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getClsfrs () {
      const classes = this.event.clsfrs.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getTime () {
      const start = this.event.time.vl.substring(11,16)
      const end = this.event.time.vle.substring(11,16)
      if (start === '00:00' && end === '00:00') {
        return ''
      } else {
        return start + ' - ' + end
      }
    },
    getDuration () {
      if (this.getTime() === '') {
        return ''
      }

      let start = new Date(this.event.time.vl)
      let end = new Date(this.event.time.vle)
      let drtn = end - start
      if (drtn < 3600000) {
        return ', ' + drtn/1000/60 + 'min'
      } else {
        if (drtn % 3600000 > 0) {
          return ', ' + ~~(drtn/1000/60/60) + 'h ' + (drtn % 3600000)/1000/60 + 'min'  
        } else {
          return ', ' + drtn/1000/60/60 + 'h'
        }
      }
    },
    getTimeDuration () {
      const start = this.event.time.vl.substring(11,16)
      const end = this.event.time.vle.substring(11,16)
      if (start === '00:00' && end === '00:00') {
        return ''
      } else {
        if (start === end) {
          return start
        } else {
          const sTime = start + ' - ' + end + ', '
          let dStart = new Date(this.event.time.vl)
          let dEnd = new Date(this.event.time.vle)
          let dDrtn = dEnd - dStart

          if (dDrtn < 3600000) {
            return sTime + dDrtn/1000/60 + 'min'
          } else {
            if (dDrtn % 3600000 > 0) {
              return sTime +  ~~(dDrtn/1000/60/60) + 'h ' + (dDrtn % 3600000)/1000/60 + 'min'  
            } else {
              return sTime + dDrtn/1000/60/60 + 'h'
            }
          }

        }
      }

    },

    getTimeZone () {
      if (this.getTime() === '') {
        return ''
      }

      return '(UTC' + this.event.time.vle.substring(19,26) + ' ' + this.event.time.tz + ')'
    },
    getLocality () {
      return this.event.locality.dtl.vl + '  ' + this.event.locality.dtl.region  + '  ' + this.event.locality.dtl.gps
    },
    getLinks () {
      return this.event.msrs.filter((item) => (item.nm === 'HI-BI General Values Link Items'))[0].clctn
    },

    viewEvent () {
      emitter.emit('viewEvent', {id: this.event.id})
    }
  }
}
</script>

<style lang="scss" scoped>
.day-event {
  margin-top: 2px;
  margin-bottom: 2px;
  display: block;
  color: #4C4C4C;
  background-color:#FFFFFF;
  padding: 2px;

  .details {
    display: block;
  }

  .icons .fa {
    padding: 0 2px;
  }

  input {
    background: none;
    border: 0;
    border-bottom: 1px solid #FFF;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .ev-event-top {
    display: flex;
    flex-direction: row;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: #FFF;
  }

  .ev-event-classes {
    flex: 1 0 50px
  }
  .ev-event-edit {
    flex: 0 0 10px;
    display: block;
  }
  .ev-button-edit {
    float: right; 
    color: var(--main-4-color);
    background-color: #FFF;
    border-radius: 8px;
    cursor: pointer;
  }
  button {
    position: relative;
    top: 0.25em;
  }

  .has-text-centered a {
    color: var(--main-4-color)
  }

}
</style>

<style lang="scss">
  .has-text-centered a {
    color: var(--main-1-color);
  }

  input {
    accent-color: var(--main-4-color);
  }
</style>
