<template>
  <div class="clndr-cell">
    <div class="day">
      <div class="day-banner has-text-centered" :style="getDayBackgroundColor"><strong>{{ dayDay }}</strong>&nbsp; {{ dayWeekday }} &nbsp; <b>{{ dayMonth }}</b>
      &nbsp; <b>{{ dayYear }}</b>
      </div>
      <div class="day-banner has-text-centered" :style="getDayBackgroundColor" style="text-align: right; padding: 2px;">{{ getRestDate(day.date) }}</div>
      <div class="day-details">
        <div class="day-number">  </div>
        <hibiCalendarEvent v-for="event in dayEvents()"
          :key="event.id"
          :event="event"/>
      </div>
    </div>
  </div>
</template>

<script>
import hibiCalendarEvent from './hibicalendar-event.vue'

export default {
  name: 'hibiCalendarDay',
  props: ['day'],
  data () {
    const d = new Date(this.day.date)
    const timeRTF = new Intl.DateTimeFormat('en-us', {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      fractionalSecondDigits: 3,
      hour12: false,
      timeZone: 'UTC'
    })
    // console.log(this.day.date, timeRTF.formatToParts(d).find((el) => (el.type === 'weekday')).value.toUpperCase())
    return {
      dayWeekday: timeRTF.formatToParts(d).find((el) => (el.type === 'weekday')).value, //.toUpperCase(),
      dayDay: timeRTF.formatToParts(d).find((el) => (el.type === 'day')).value,
      dayMonth: timeRTF.formatToParts(d).find((el) => (el.type === 'month')).value.substring(0, 3),
      dayYear: timeRTF.formatToParts(d).find((el) => (el.type === 'year')).value
    }
  },
  components: {
    hibiCalendarEvent
  },
  created () {
    // console.log('CalendarDay created')
    // console.log(this.day.events)
  },
  computed: {
    getDayBackgroundColor () {
      const d = new Date()
      const dt = d.toISOString().substring(0, 10)

      let style = `background-color: ${'rgb(0,109,129)'}`  // #875F78

      if (this.day.date <= dt) {
        if (this.day.date == dt) { //current day
          style = `background-color: ${'rgb(197,142,11)'}; color: ${'#FFFFFF'}` // #B9C089, #FF9999
        } else { //past days
          style = `background-color: ${'rgb(69,73,85)'}; color: ${'#FFFFFF'}` // rgb(18,44,68)    #A89E87
        }
      }
      return style
    }
  },
  methods: {
    getRestDate (planDate) {
      const crDt = Date.now()
      const plnDt = Date.parse(planDate)
      const rstDt = plnDt - crDt
      const rstDy = Math.ceil(rstDt / 86400000)
      const rstWk = Math.floor(rstDy / 7)
      if (rstDy >= 0) {
        if (rstDy === 0) {
          return 'Today'
        } else {
          if (rstWk > 0) {
            return rstWk + ' W (' + rstDy + ' d) ->'
          } else {
            return rstDy + ' d ->'
          }
        }
      } else {
        if (rstWk < -1) {
            return (-rstWk-1) + ' W (' + (-rstDy) + ' d) <- Past'
          } else {
            return (-rstDy) + ' d <- Past'
          }
      }
    },
    dayEvents () {
      return this.day.events
    }
  }
}
</script>

<style lang="scss" scoped>
.day {
  background-color: #EBEBEB; //#B4DAD0 /*#4A4A4A*/;
  color: #FFF;
  /*border-left: 0px solid #8F8F8F;
  border-bottom: 0px solid #8F8F8F;*/
  font-size: 12px;
  /*cursor: pointer;*/

  /*&:hover {
    background: darken(#4A4A4A,3%);
  }*/

  .day-banner {
    font-family: Helvetica, Arial, sans-serif;
    background-color: #875F78; // #3D6279 /*#333333*/;
    color: #FFF;
    padding: 4px;
    // text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    // font-weight: 700;
    line-height: 1;
  }

  //.day-details {
    //padding: 4px;
  //}

  /*&:last-child {
    border-right: 1px solid #8F8F8F;
  }*/
}
</style>
